import { gql } from '@apollo/client'

// Define the query, taking an `id` parameter
export const GET_ACCOUNT_ASSET_BALANCES = gql`
  query GetAccountAssetBalances($id: ID!) {
    account(id: $id) {
      assetBalances {
        id
        balance
        asset {
          assetAddress
          assetType
          assetId
        }
      }
    }
  }
`
