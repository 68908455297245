import { Interface } from '@ethersproject/abi'
import { BigNumber } from '@ethersproject/bignumber'
import { Currency, CurrencyAmount, NONFUNGIBLE_POSITION_MANAGER_ADDRESSES, Token } from '@uniswap/sdk-core'
import { nativeOnChain } from '@uniswap/smart-order-router'
import IUniswapV2PairJSON from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { ADDRESS_ZERO } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import ERC20_ABI from 'abis/erc20.json'
import ERC721_ABI from 'abis/erc721.json'
import { DEFAULT_CHAIN_ID, DEFAULT_V4_CHAIN_ID } from 'constants/misc'
import { useAssetManager } from 'hooks/useContract'
import { useV3PositionsFromTokenIds } from 'hooks/useV3Positions'
import { AccountIdentityKey, AssetType, TransactionAssetKey } from 'hooks/v4/useAssetManagerOrderPermit'
import { CallStateResult, useMultipleContractSingleData, useSingleContractMultipleData } from 'lib/hooks/multicall'
import { TokenAmountBalances, TokenBalances } from 'lib/hooks/useTokenList/sorting'
import { useEffect, useMemo, useState } from 'react'
import { toV2LiquidityToken, useTrackedTokenPairs } from 'state/user/hooks'
import { PositionDetails } from 'types/position'

import { AssetBalance, fetchAccountAssetBalances } from '../Graph/v4GraphFetch'

type TokenMetadata = {
  currencyAmount: CurrencyAmount<Currency>
}

type V2LPTokenMetadata = TokenMetadata & {
  token0?: string
  token1?: string
}

type NFTMetadata = {
  tokenId: string
  tokenAddress: string
  balance: string
  name: string
}

type V3LPTokenMetadata = {
  tokenId: string
  tokenAddress: string
  position: PositionDetails
}

type AssetBalanceMetadata = {
  TokenMetadata: TokenMetadata[]
  V2LPTokenMetadata: V2LPTokenMetadata[]
  NFTMetadata: NFTMetadata[]
  V3LPTokenMetadata: V3LPTokenMetadata[]
}

// eslint-disable-next-line import/no-unused-modules
export function useAccountBalances(
  accountHash?: string,
  chainId?: number
): {
  isLoading: boolean
  assetBalances?: AssetBalance[]
} {
  const [assetBalances, setAssetBalances] = useState<AssetBalance[] | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // Reset balances and loading state when accountHash changes
    setAssetBalances(undefined)
    setIsLoading(true)

    const loadAssetBalances = async () => {
      try {
        const balances = await fetchAccountAssetBalances(accountHash)
        setAssetBalances(balances)
      } catch (error) {
        console.error('Error fetching asset balances:', error)
      } finally {
        setIsLoading(false)
      }
    }

    if (accountHash) {
      loadAssetBalances()
    } else {
      // No account hash means we are not loading
      setIsLoading(false)
    }
  }, [accountHash, chainId])

  return { isLoading, assetBalances }
}

// eslint-disable-next-line import/no-unused-modules
export function useRPCAvalableAccountBalance(accountHash: string | undefined, currencies: (Token | Currency)[]) {
  const contractParams = accountHash
    ? currencies.map((x) => [accountHash, x.isNative ? [ADDRESS_ZERO, '0', 0] : [x.address, '0', 1]])
    : []

  const assetManager = useAssetManager()
  const results = useSingleContractMultipleData(assetManager, 'getAvailableBalanceByAccount', contractParams)

  const loading = useMemo(() => results.some(({ loading }) => loading), [results])
  const error = useMemo(() => results.some(({ error }) => error), [results])

  const currenciesAndBalance = useMemo(() => {
    if (!loading && !error) {
      return results.map((call, i) => {
        const currency = currencies[i]
        const result = call.result as CallStateResult
        return {
          currency,
          balanceAmount: CurrencyAmount.fromRawAmount(currency, result[0].toString()),
        }
      })
    }
    return []
  }, [loading, error, results, currencies])

  return {
    loading,
    currenciesAndBalance,
  }
}

// eslint-disable-next-line import/no-unused-modules
export function useRPCAvailbleAccountBalanceV2LP(accountHash?: string) {
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )

  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((x) => x.liquidityToken),
    [tokenPairsWithLiquidityTokens]
  )

  const { loading, currenciesAndBalance } = useRPCAvalableAccountBalance(accountHash, liquidityTokens)

  const v2LPWithBalances = useMemo(() => {
    if (!loading && tokenPairsWithLiquidityTokens) {
      return currenciesAndBalance
        .map((currencyWithBalance, i) => {
          return {
            ...tokenPairsWithLiquidityTokens[i],
            balanceAmount: currencyWithBalance.balanceAmount,
          }
        })
        .filter((x) => x.balanceAmount.greaterThan('0'))
    }
    return []
  }, [currenciesAndBalance, loading, tokenPairsWithLiquidityTokens])

  return {
    loading,
    v2LPWithBalances,
  }
}

// eslint-disable-next-line import/no-unused-modules
export function useAccountBalanceMetadata(accountHash?: string): {
  metadata?: AssetBalanceMetadata
  isError: boolean
  isLoading: boolean
  isSyncing: boolean
  isValid: boolean
} {
  const { chainId } = useWeb3React()
  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID
  const { isLoading: isBalanceLoading, assetBalances: assetBalancesOrDefault } = useAccountBalances(
    accountHash,
    chainIdOrDefault
  )
  const assetBalances = useMemo(() => assetBalancesOrDefault || [], [assetBalancesOrDefault])

  const erc20Balances = useMemo(
    () => assetBalances.filter((balance) => balance.asset.assetType === AssetType.Token),
    [assetBalances]
  )
  const nativeBalance = useMemo(
    () => assetBalances.find((balance) => balance.asset.assetType === AssetType.Native),
    [assetBalances]
  )
  const erc721Balances = useMemo(
    () => assetBalances.filter((balance) => balance.asset.assetType === AssetType.NFT),
    [assetBalances]
  )

  const erc20Addresses = erc20Balances.map((balance) => balance.asset.assetAddress)
  const erc20Interface = new Interface(ERC20_ABI)
  const pairInterface = new Interface(IUniswapV2PairJSON.abi)
  const erc721Interface = new Interface(ERC721_ABI)

  const symbols = useMultipleContractSingleData(erc20Addresses, erc20Interface, 'symbol')
  const decimals = useMultipleContractSingleData(erc20Addresses, erc20Interface, 'decimals')
  const lpToken0Calls = useMultipleContractSingleData(erc20Addresses, pairInterface, 'token0')
  const lpToken1Calls = useMultipleContractSingleData(erc20Addresses, pairInterface, 'token1')

  const v3NFTAddress = NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId ?? DEFAULT_V4_CHAIN_ID].toLowerCase()

  const erc721Addresses = erc721Balances
    .map((balance) => balance.asset.assetAddress)
    .filter((x) => x.toLowerCase() != v3NFTAddress)
  const v3TokenIds = erc721Balances
    .filter((x) => x.asset.assetAddress.toLowerCase() == v3NFTAddress && BigNumber.from(x.balance).gt(0))
    .map((balance) => balance.asset.assetId)

  const v3PositionResults = useV3PositionsFromTokenIds(v3TokenIds)

  const erc721Names = useMultipleContractSingleData(erc721Addresses, erc721Interface, 'name')

  const isError = useMemo(
    () => [symbols, decimals, erc721Names].some((calls) => calls.some(({ error }) => error)),
    [symbols, decimals, erc721Names]
  )

  const isLoading = useMemo(
    () =>
      isBalanceLoading ||
      v3PositionResults.loading ||
      [symbols, decimals, lpToken0Calls, lpToken1Calls, erc721Names].some((calls) =>
        calls.some(({ loading }) => loading)
      ),
    [isBalanceLoading, symbols, decimals, lpToken0Calls, lpToken1Calls, erc721Names, v3PositionResults]
  )

  const isSyncing = useMemo(
    () =>
      [symbols, decimals, lpToken0Calls, lpToken1Calls, erc721Names].some((calls) =>
        calls.some(({ syncing }) => syncing)
      ),
    [symbols, decimals, lpToken0Calls, lpToken1Calls, erc721Names]
  )

  const isValid = useMemo(
    () => [symbols, decimals, erc721Names].some((calls) => calls.some(({ valid }) => valid)),
    [symbols, decimals, erc721Names]
  )

  const { TokenMetadata, V2LPTokenMetadata } = useMemo(() => {
    if (isLoading) return { TokenMetadata: [], V2LPTokenMetadata: [] }

    const TokenMetadata: TokenMetadata[] = []
    const V2LPTokenMetadata: V2LPTokenMetadata[] = []

    if (nativeBalance) {
      const native = nativeOnChain(chainIdOrDefault)
      TokenMetadata.push({
        currencyAmount: CurrencyAmount.fromRawAmount(native, nativeBalance.balance),
      })
    }

    erc20Balances.forEach((balance, index) => {
      const isLP = Boolean(lpToken0Calls[index]?.result?.[0] && lpToken1Calls[index]?.result?.[0])
      const symbol = typeof symbols[index]?.result?.[0] === 'string' ? symbols[index]?.result?.[0] : 'UNKNOWN'
      const decimalsValue = typeof decimals[index]?.result?.[0] === 'number' ? decimals[index]?.result?.[0] : 18

      if (isLP) {
        const token0 = lpToken0Calls[index]?.result?.[0]
        const token1 = lpToken1Calls[index]?.result?.[0]
        V2LPTokenMetadata.push({
          currencyAmount: CurrencyAmount.fromRawAmount(
            new Token(chainIdOrDefault, balance.asset.assetAddress, decimalsValue, symbol),
            balance.balance
          ),
          token0: typeof token0 === 'string' ? token0 : undefined,
          token1: typeof token1 === 'string' ? token1 : undefined,
        })
      } else {
        TokenMetadata.push({
          currencyAmount: CurrencyAmount.fromRawAmount(
            new Token(chainIdOrDefault, balance.asset.assetAddress, decimalsValue, symbol),
            balance.balance
          ),
        })
      }
    })

    return { TokenMetadata, V2LPTokenMetadata }
  }, [isLoading, nativeBalance, erc20Balances, chainIdOrDefault, lpToken0Calls, lpToken1Calls, symbols, decimals])

  const { NFTMetadata, V3LPTokenMetadata } = useMemo(() => {
    if (isLoading) return { NFTMetadata: [], V3LPTokenMetadata: [] }

    const NFTMetadata: NFTMetadata[] = []
    const V3LPTokenMetadata: V3LPTokenMetadata[] = []

    erc721Balances.forEach((balance, index) => {
      const name = typeof erc721Names[index]?.result?.[0] === 'string' ? erc721Names[index]?.result?.[0] : 'UNKNOWN'
      NFTMetadata.push({
        tokenId: balance.asset.assetId,
        tokenAddress: balance.asset.assetAddress,
        balance: balance.balance,
        name,
      })
    })

    v3PositionResults.positions?.forEach((position) => {
      V3LPTokenMetadata.push({
        tokenId: position.tokenId.toString(),
        tokenAddress: v3NFTAddress,
        position,
      })
    })

    return { NFTMetadata, V3LPTokenMetadata }
  }, [isLoading, erc721Balances, v3PositionResults.positions, erc721Names, v3NFTAddress])

  return {
    metadata: {
      TokenMetadata,
      V2LPTokenMetadata,
      NFTMetadata,
      V3LPTokenMetadata,
    },
    isError,
    isLoading,
    isSyncing,
    isValid,
  }
}

// eslint-disable-next-line import/no-unused-modules
export function useV4ERC20AccountBalance(accountHash?: string) {
  const { metadata, isLoading } = useAccountBalanceMetadata(accountHash)

  const v4AccountBalances: TokenAmountBalances = useMemo(() => {
    const filteredBalances = (metadata?.TokenMetadata.map((x) => x.currencyAmount) || []) as CurrencyAmount<Token>[]

    return (
      filteredBalances.reduce((balanceMap, tokenBalance) => {
        const isNative = tokenBalance.currency?.isNative
        const address = isNative ? ADDRESS_ZERO : tokenBalance.currency.address.toLowerCase()

        balanceMap[address] = tokenBalance

        // Add ADDRESS_ZERO entry if token is native
        if (isNative) {
          balanceMap['ETH'] = tokenBalance
        }

        return balanceMap
      }, {} as TokenAmountBalances) ?? {}
    )
  }, [metadata])

  return { v4AccountBalances, isLoading }
}

// eslint-disable-next-line import/no-unused-modules
export function useV4ERC20AccountBalanceFormatted(accountHash?: string) {
  const { v4AccountBalances, isLoading } = useV4ERC20AccountBalance(accountHash)

  const v4FormattedBalances: TokenBalances = useMemo(() => {
    return Object.entries(v4AccountBalances).reduce((balanceMap, [address, tokenBalance]) => {
      const usdValue = Number(0) // Replace with actual USD conversion logic if available
      const balance = Number(tokenBalance.toFixed())

      balanceMap[address] = { usdValue, balance }
      return balanceMap
    }, {} as TokenBalances)
  }, [v4AccountBalances])

  return { v4AccountBalances: v4FormattedBalances, isLoading }
}
export function useOwnerOfAssetAndAvailable(
  accountIdentity: AccountIdentityKey | undefined,
  assetKey: TransactionAssetKey | undefined
): boolean {
  const [isAvailable, setIsAvailable] = useState(false)
  const assetManager = useAssetManager()

  useEffect(() => {
    if (!assetManager || !accountIdentity || !assetKey) return
    assetManager.getAvailableBalance(accountIdentity, assetKey).then((r) => setIsAvailable(r.gt(0)))
  }, [accountIdentity, assetKey, assetManager])

  return isAvailable
}
