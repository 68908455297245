import React from 'react'
import styled from 'styled-components'

import { useAccountBalanceMetadata } from '../Hooks/useAccountBalanceMetadata'

type AccountRequest = {
  accountHash: string
}

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;

  th,
  td {
    padding: 12px;
    border: 1px solid ${({ theme }) => '#e0e0e0'};
    text-align: left;
  }

  th {
    background-color: ${({ theme }) => '#0070f3'};
    color: ${({ theme }) => '#ffffff'};
    font-weight: bold;
  }

  td {
    background-color: ${({ theme }) => '#f9f9f9'};
    color: ${({ theme }) => '#333333'};
  }
`

const LoadingMessage = styled.p`
  color: ${({ theme }) => '#888888'};
`

const ErrorMessage = styled.p`
  color: ${({ theme }) => '#ff4d4f'};
`

const SectionTitle = styled.h2`
  margin-bottom: 16px;
  color: ${({ theme }) => '#333333'};
  ~`

const AssetBalances = ({ accountHash }: { accountHash: string }) => {
  const { metadata, isLoading, isError } = useAccountBalanceMetadata(accountHash)

  if (isError) return <ErrorMessage>Error loading asset balances: Failed to fetch metadata</ErrorMessage>
  if (isLoading || !metadata) return <LoadingMessage>Loading asset balances...</LoadingMessage>

  return (
    <div>
      <SectionTitle>Asset Balances for Account: {accountHash}</SectionTitle>
      <StyledTable>
        <thead>
          <tr>
            <th>ID</th>
            <th>Balance</th>
            <th>Asset Type</th>
            <th>Additional Info</th>
          </tr>
        </thead>
        <tbody>
          {metadata.TokenMetadata.map((balanceData) => {
            const isNative = balanceData.currencyAmount.currency.isNative
            return (
              <tr key={isNative ? 'Native' : balanceData.currencyAmount.currency.wrapped.address}>
                <td>{isNative ? 'Native' : balanceData.currencyAmount.currency.wrapped.address}</td>
                <td>{balanceData.currencyAmount.toExact()}</td>
                <td>Token</td>
                <td>
                  Symbol: {balanceData.currencyAmount.currency.symbol}, Decimals:{' '}
                  {balanceData.currencyAmount.currency.decimals}
                </td>
              </tr>
            )
          })}
          {metadata.V2LPTokenMetadata.map((lpbalanceData) => (
            <tr key={lpbalanceData.currencyAmount.currency.wrapped.address}>
              <td>{lpbalanceData.currencyAmount.currency.wrapped.address}</td>
              <td>{lpbalanceData.currencyAmount.toExact()}</td>
              <td>V2 LP Token</td>
              <td>
                Symbol: {lpbalanceData.currencyAmount.currency.symbol}, Decimals:{' '}
                {lpbalanceData.currencyAmount.currency.decimals}, Token0: {lpbalanceData.token0}, Token1:{' '}
                {lpbalanceData.token1}
              </td>
            </tr>
          ))}

          {metadata.NFTMetadata.map((nft) => (
            <tr key={`${nft.tokenAddress}_${nft.tokenId}`}>
              <td>{nft.tokenId}</td>
              <td>{nft.balance}</td>
              <td>NFT</td>
              <td>Name: {nft.name}</td>
            </tr>
          ))}
          {metadata.V3LPTokenMetadata.map((v3LP) => (
            <tr key={`${v3LP.tokenAddress}_${v3LP.tokenId}`}>
              <td>{v3LP.tokenId}</td>
              <td>{1}</td>
              <td>V3 LP Token</td>
              <td>
                Token0: {v3LP.position.token0}, Token1: {v3LP.position.token1}
              </td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </div>
  )
}

export default AssetBalances
