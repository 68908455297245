import { useWeb3React } from '@web3-react/core'
import { useNFTAccessPassContract } from 'hooks/useContract'
import { useSingleCallResult, useSingleContractMultipleData } from 'lib/hooks/multicall'
import { useMemo } from 'react'

interface NFTData {
  tokenId: string
  imageUrl: string
}

const DEFAULT_IMAGE = 'https://example.com/default-image.png'

const useOwnedNFTs = () => {
  const { account } = useWeb3React()
  const nftAccessPass = useNFTAccessPassContract()

  // Fetch NFT balance once
  const balanceResult = useSingleCallResult(nftAccessPass, 'balanceOf', [account])
  const balance = balanceResult?.result?.[0]?.toNumber() ?? 0

  // Prepare token ID calls (only change when balance updates)
  const tokenIdCalls = useMemo(() => {
    return balance > 0 ? Array.from({ length: balance }, (_, i) => [account, i]) : []
  }, [account, balance])

  // Fetch token IDs
  const tokenIdsResults = useSingleContractMultipleData(nftAccessPass, 'tokenOfOwnerByIndex', tokenIdCalls)

  // Extract token IDs (stable reference)
  const tokenIds = useMemo(() => {
    return tokenIdsResults.map(({ result }) => result?.[0]?.toString()).filter(Boolean) as string[]
  }, [tokenIdsResults])

  // Prepare token URI calls (only change when tokenIds update)
  const tokenURICalls = useMemo(() => {
    return tokenIds.length > 0 ? tokenIds.map((tokenId) => [tokenId]) : []
  }, [tokenIds])

  // Fetch token URIs
  const tokenUrisResults = useSingleContractMultipleData(nftAccessPass, 'tokenURI', tokenURICalls)

  // Determine if URIs have been populated
  const urisPopulated = tokenUrisResults.every(({ result }) => !!result)

  // Extract token URIs (stable reference)
  const tokenUris = useMemo(() => {
    return tokenUrisResults.map(({ result }) => result?.[0] ?? DEFAULT_IMAGE)
  }, [urisPopulated]) // Only updates when all URIs are ready

  // Compute final NFT list (only update when all data is available)
  const ownedNFTs = useMemo(() => {
    if (!urisPopulated || tokenIds.length === 0) return []

    return tokenIds.map((tokenId, index) => ({
      tokenId,
      imageUrl: `${tokenUris[index]}/${tokenId}`,
    }))
  }, [urisPopulated, tokenIds, tokenUris])

  return ownedNFTs
}

export default useOwnedNFTs
