import { BigNumber } from '@ethersproject/bignumber'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import { useAssetManager } from 'hooks/useContract'
import { getAssetKeyFromCurrency, TransactionAssetKey } from 'hooks/v4/useAssetManagerOrderPermit'
import { useEffect, useMemo, useState } from 'react'

// eslint-disable-next-line import/no-unused-modules
export enum AssetBalanceType {
  Total,
  Available,
  Locked,
}

// eslint-disable-next-line import/no-unused-modules
export function useAssetBalance(
  accountId: string | undefined,
  assetKey: TransactionAssetKey | undefined,
  balanceType: AssetBalanceType
): BigNumber | undefined {
  const [balance, setBalance] = useState<BigNumber | undefined>(undefined)
  const assetManager = useAssetManager()

  useEffect(() => {
    if (!accountId || !assetKey || !assetManager) return

    const fetchBalance = async () => {
      if (!assetKey) return
      try {
        let balance

        switch (balanceType) {
          case AssetBalanceType.Total:
            balance = await assetManager.getTotalBalanceByAccount(accountId, assetKey)
            break
          case AssetBalanceType.Available:
            balance = await assetManager.getAvailableBalanceByAccount(accountId, assetKey)
            break
          case AssetBalanceType.Locked:
            balance = await assetManager.getLockedBalanceByAccount(accountId, assetKey)
            break
          default:
            throw new Error('Invalid balance type')
        }

        setBalance(balance)
      } catch (error) {
        console.error('Error fetching total balance:', error)
      }
    }

    fetchBalance()
  }, [accountId, assetKey, assetManager, balanceType])

  return balance
}

// eslint-disable-next-line import/no-unused-modules
export function useCurrencyAssetBalance(
  accountId: string | undefined,
  currency: Currency | null | undefined,
  balanceType: AssetBalanceType
): CurrencyAmount<Currency> | undefined {
  const assetKey = useMemo(() => getAssetKeyFromCurrency(currency), [currency])
  const balance = useAssetBalance(accountId, assetKey, balanceType)
  return useMemo(() => {
    if (!balance || !currency) return undefined
    return CurrencyAmount.fromRawAmount(currency, balance.toString())
  }, [balance, currency])
}
