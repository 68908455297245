import { Currency, Percent } from '@uniswap/sdk-core'
import { useIsAssetManagerActive } from 'pages/MintNFT/Hooks/useAssetManager'
import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { currencyId } from '../../utils/currencyId'
import AddLiquidityWidget from './widget'

const DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE = new Percent(50, 10_000)

export default function AddLiquidity() {
  const { currencyIdA, currencyIdB } = useParams<{ currencyIdA?: string; currencyIdB?: string }>()
  const navigate = useNavigate()
  const isAssetManager = useIsAssetManagerActive()

  const handleCurrencyASelect = useCallback(
    (currencyA: Currency) => {
      const newCurrencyIdA = currencyId(currencyA)
      if (newCurrencyIdA === currencyIdB) {
        navigate(`/add/v2/${currencyIdB}/${currencyIdA}`)
      } else {
        navigate(`/add/v2/${newCurrencyIdA}/${currencyIdB}`)
      }
    },
    [currencyIdB, navigate, currencyIdA]
  )
  const handleCurrencyBSelect = useCallback(
    (currencyB: Currency) => {
      const newCurrencyIdB = currencyId(currencyB)
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
          navigate(`/add/v2/${currencyIdB}/${newCurrencyIdB}`)
        } else {
          navigate(`/add/v2/${newCurrencyIdB}`)
        }
      } else {
        navigate(`/add/v2/${currencyIdA ? currencyIdA : 'ETH'}/${newCurrencyIdB}`)
      }
    },
    [currencyIdA, navigate, currencyIdB]
  )

  return (
    <>
      <AddLiquidityWidget
        currencyIdA={currencyIdA}
        currencyIdB={currencyIdB}
        handleCurrencyASelect={handleCurrencyASelect}
        handleCurrencyBSelect={handleCurrencyBSelect}
        isAssetManager={isAssetManager}
      />
    </>
  )
}
