// eslint-disable-next-line no-restricted-imports
import { Currency, NativeCurrency, Token } from '@uniswap/sdk-core'
import { useAccountIdentityWithHash } from 'hooks/v4/useAssetManagerOrderPermit'
import { useCallback, useState } from 'react'

import { CurrencySearchBase } from '../../../components/SearchModal/CurrencySearchBase'
import { useV4ERC20AccountBalanceFormatted } from '../Hooks/useAccountBalanceMetadata'

// eslint-disable-next-line import/no-unused-modules
export interface CurrencySearchProps {
  isOpen: boolean
  onDismiss: () => void
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency, hasWarning?: boolean) => void
  otherSelectedCurrency?: Currency | null
  showCommonBases?: boolean
  showCurrencyAmount?: boolean
  disableNonToken?: boolean
  onlyShowCurrenciesWithBalance?: boolean
  showManageView?: () => void
}

export function V4CurrencySearch({
  selectedCurrency,
  onCurrencySelect,
  otherSelectedCurrency,
  showCommonBases,
  showCurrencyAmount,
  disableNonToken,
  onDismiss,
  isOpen,
  onlyShowCurrenciesWithBalance,
  showManageView,
}: CurrencySearchProps) {
  const [allCurrencies, setAllCurrenciesState] = useState<(NativeCurrency | Token)[]>([])
  const setAllCurrencies = useCallback((currencies: (NativeCurrency | Token)[]) => {
    setAllCurrenciesState(currencies)
  }, [])

  const accountIdentity = useAccountIdentityWithHash()
  const { v4AccountBalances, isLoading } = useV4ERC20AccountBalanceFormatted(accountIdentity?.accountHash)

  const handleCurrencySelect = useCallback(
    (currency: Currency, hasWarning?: boolean) => {
      onCurrencySelect(currency, hasWarning)
      if (!hasWarning) onDismiss()
    },
    [onDismiss, onCurrencySelect]
  )

  return (
    <CurrencySearchBase
      isOpen={isOpen}
      onDismiss={onDismiss}
      selectedCurrency={selectedCurrency}
      otherSelectedCurrency={otherSelectedCurrency}
      showCommonBases={showCommonBases}
      showCurrencyAmount={showCurrencyAmount}
      disableNonToken={disableNonToken}
      onlyShowCurrenciesWithBalance={onlyShowCurrenciesWithBalance}
      showManageView={showManageView}
      getAccountBalance={() => ({ balances: v4AccountBalances, isLoading })}
      setAllCurrencies={setAllCurrencies}
      onCurrencySelect={handleCurrencySelect}
    />
  )
}
