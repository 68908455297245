import { v4ApolloClient } from './apollo'
import { GET_ACCOUNT_ASSET_BALANCES } from './v4GraphQueries'

// eslint-disable-next-line import/no-unused-modules
export interface Asset {
  assetAddress: string
  assetType: number
  assetId: string
}

// eslint-disable-next-line import/no-unused-modules
export interface AssetBalance {
  id: string
  balance: string
  asset: Asset
}

// eslint-disable-next-line import/no-unused-modules
export interface Account {
  assetBalances: AssetBalance[]
}

// eslint-disable-next-line import/no-unused-modules
export interface GetAccountAssetBalancesData {
  account: Account
}

export async function fetchAccountAssetBalances(accountId?: string): Promise<AssetBalance[] | undefined> {
  if (!accountId) {
    return undefined
  }

  try {
    const { data } = await v4ApolloClient.query<GetAccountAssetBalancesData>({
      query: GET_ACCOUNT_ASSET_BALANCES,
      variables: { id: accountId },
    })

    return data.account.assetBalances
  } catch (error) {
    console.error('Error fetching account asset balances:', error)
    return undefined
  }
}
