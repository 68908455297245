/* eslint-disable import/no-unused-modules */
import { BigNumber } from '@ethersproject/bignumber'
import { NATIVE_CURRENCY } from '@uniswap/smart-order-router'
import { useWeb3React } from '@web3-react/core'
import { ButtonPrimary, ButtonSecondary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { ScrollBarStyles } from 'components/Common'
import GenericToggle from 'components/GenericToggle'
import Img from 'components/Img'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import Modal from 'components/Modal'
import Row, { RowBetween } from 'components/Row'
import { DEFAULT_CHAIN_ID } from 'constants/misc'
import { nativeOnChain } from 'constants/tokens'
import { parseEther } from 'ethers/lib/utils'
import { SpookyLaunchpad } from 'hooks/useContract'
import { formatWeiToDecimal } from 'nft/utils'
import { AdvancedHeader } from 'pages/TokenPage/AdvancedTokenSwapDetails'
import { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { ArrowDown, ArrowLeft, ArrowUp } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { useTransactionAdder } from 'state/transactions/hooks'
import styled, { useTheme } from 'styled-components'
import { BREAKPOINTS } from 'theme'
import { calculateGasMargin } from 'utils/calculateGasMargin'

import magicpete from '../../assets/images/magicpete.webp'
import magicpog from '../../assets/images/magicpog.webp'
import { pinata } from '../../utils/config'
import Chatbox from './Chat/chatWidget'
import { CREATION_PRICE_IN_ETHER, DELTA_MCAP_REQUIRED, STARTING_MCAP_ETHER } from './const'
import {
  useGet24hVolume,
  useGetRecentSwaps,
  useLaunchpadTokensFromPools,
  useWNativeUSDPrice,
} from './hooks/useLaunchpadTokens'

enum HeaderType {
  MarketCap = 'Market Cap',
  Newest = 'Newest',
  Bump = 'Bump',
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const formatMarketCap = (
  price: string,
  wnativePrice: number,
  showUSDPrice: boolean,
  nativeTokenName: string
) => {
  if (!wnativePrice || !price) return '0.00'
  if (!showUSDPrice) wnativePrice = 1
  let mcap = formatWeiToDecimal(
    BigNumber.from(price)
      .mul((1000000000 * wnativePrice).toFixed(0))
      .toHexString()
  )
  if (showUSDPrice) mcap = '$' + mcap
  else mcap += ' ' + nativeTokenName
  return mcap
}

export const formatVolume = (
  volume24h: string,
  wnativePrice: number,
  showUSDPrice: boolean,
  nativeTokenName: string
) => {
  if (!wnativePrice || !volume24h) return '0.00'
  if (!showUSDPrice) wnativePrice = 1

  let volume =
    volume24h.length == 0 || volume24h == '0'
      ? '0.00'
      : formatWeiToDecimal(
          BigNumber.from(volume24h)
            .mul((1000000000 * wnativePrice).toFixed(0))
            .div(1000000000)
            .toHexString()
        )
  if (showUSDPrice) volume = '$' + volume
  else volume += ' ' + nativeTokenName
  return volume
}

const neonGlow = (color: string) => `
  box-shadow: 0 0 5px ${color},
              0 0 10px ${color},
              0 0 15px ${color};
`

const LaunchpadWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 24px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
  // padding: 24px;

  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    grid-template-columns: 1fr;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const SwitchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`

const Card = styled.div`
  background: ${({ theme }) => theme.surface1};
  border-radius: 16px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.surface3};
`

const PepeCard = styled(Card)`
  background: none;
  border: none;
  text-align: left;
  height: 300px;
  gap: 10px;
  padding: 0;
  width: 100%;
  min-width: 0;
  flex: 1;
`

const TrendingToken = styled.div`
  background: ${({ theme }) => theme.surface1};
  display: flex;
  flex: 1;
  height: 145px;
  width: 100%;
  min-width: 0;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.surface3};
  transition: all 0.3s ease;
  position: relative;
  animation: fadeIn 0.5s ease-out;
  cursor: pointer;

  &:hover {
    transform: translateY(-4px) scale(1.02);
    ${({ theme }) => neonGlow(theme.accent1 + '40')}
    border-color: ${({ theme }) => theme.accent1};
  }

  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: 18px;
    z-index: -1;
    animation: borderGlow 3s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::before {
    opacity: 1;
  }

  @keyframes borderGlow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes shake {
    0%,
    100% {
      transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
      transform: translateX(-2px);
    }
    20%,
    40%,
    60%,
    80% {
      transform: translateX(2px);
    }
  }
`

const TrendingTokenSpotlight = styled.div`
  background: ${({ theme }) => theme.surface2};
  margin-bottom: 5px;
  color: ${({ theme }) => theme.accent1};
  text-align: center;

  border: 1px solid ${({ theme }) => theme.surface3};
  border-radius: 16px 16px 0 0;
`

const LaunchCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const ActivityCard = styled(Card)`
  max-height: 300px;
  overflow-y: auto;

  background: ${({ theme }) => theme.surface2};
  color: ${({ theme }) => theme.accent1};

  ${ScrollBarStyles}
  padding: 0;
  text-align: center;

  scrollbar-gutter: stable;
  overscroll-behavior: contain;
`

const PepeAvatar = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 0 auto;
`

const ActivityItem = styled.div<{ type: number }>`
  background: ${({ theme }) => theme.surface1};

  padding: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.surface3};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  transition: background-color 0.2s;
  // border-top: 1px solid ${({ theme }) => theme.surface3};

  &:hover {
    background: ${({ theme }) => theme.surface2};
  }

  // @media screen and (max-width: ${BREAKPOINTS.sm}px) {
  //   flex-direction: column;
  //   align-items: flex-start;
  //   gap: 8px;
  // }
`

const ActivityContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  flex-wrap: wrap;
`

const Address = styled.span`
  color: ${({ theme }) => theme.accent1};
`

const Amount = styled.span`
  color: ${({ theme }) => theme.neutral1};
  // font-weight: bold;
`

const ActivityBadge = styled.span<{ type: number }>`
  background: ${({ type, theme }) => (type === 1 ? `${theme.success}33` : `${theme.critical}33`)};
  color: ${({ type, theme }) => (type === 1 ? `${theme.success}` : `${theme.critical}`)};
  // color: ${({ theme }) => theme.black};
  // text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  padding: 2px 6px;
  width: 65px;
  text-align: center;
  border-radius: 4px;
  // font-weight: bold;
  font-size: 12px;
  // animation: ${({ type }) => (type === 1 ? 'buyAnimation' : 'sellAnimation')} 2s infinite;

  // @keyframes buyAnimation {
  //   0% {
  //     transform: scale(1);
  //   }
  //   50% {
  //     transform: scale(1.1);
  //     background-color: ${({ theme }) => theme.success};
  //   }
  //   100% {
  //     transform: scale(1);
  //   }
  // }

  // @keyframes sellAnimation {
  //   0% {
  //     transform: scale(1);
  //   }
  //   50% {
  //     transform: scale(1.1);
  //     background-color: ${({ theme }) => theme.deprecated_accentWarning};
  //   }
  //   100% {
  //     transform: scale(1);
  //   }
  // }
`

const Input = styled.input`
  padding: 12px;
  border: none;
  background: ${({ theme }) => theme.surface2};
  color: ${({ theme }) => theme.neutral1};

  &:focus {
    outline: none;
    border: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.surface3};
  background: ${({ theme }) => theme.surface2};
  color: ${({ theme }) => theme.neutral1};
`

const InputText = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  border: 1px solid ${({ theme }) => theme.surface3};
  border-radius: 20px;

  background: ${({ theme }) => theme.surface1};

  align-items: center;
  justify-content: center;
  gap: 5px;
`

const TextOptional = styled.div`
  display: flex;
  // margin-left: 10px;
  color: ${({ theme }) => theme.neutral2};
`
const TokenGridSection = styled.section`
  margin-top: 20px;
  width: 100%;
  max-width: 1200px;
  background: ${({ theme }) => theme.surface1};

  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.surface3};
`

const ViewContainer = styled.div`
  display: flex;

  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.accent1};
  }
`

const TokenGrid = styled.div<TokenColumnHeaderProps>`
  display: grid;

  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));

  gap: 0 20px;
  padding: 0 20px;
  // background: ${({ theme }) => theme.surface2};
`

interface TokenColumnHeaderProps {
  isSelectedHeader: boolean
  isSelected: boolean
}

const TokenColumnHeader = styled.div<TokenColumnHeaderProps>`
  text-align: left;
  width: 100%;
  margin-bottom: 0px;
  color: ${({ theme }) => theme.accent1};
  display: grid;


  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));

  // background: ${({ theme }) => theme.surface2};
  // @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
  //   grid-template-rows: repeat(auto-fill, minmax(280px, 1fr));
  // }

  // width: 100%;
  font-size: 26px;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.accent1}};

  cursor: pointer;
  ${({ isSelected, isSelectedHeader }) =>
    isSelected &&
    !isSelectedHeader &&
    `
      display: none;

  `}
  ${({ isSelected, isSelectedHeader, theme }) =>
    isSelected &&
    isSelectedHeader &&
    `
      margin-left: 40px;
      margin-right: 40px;
      color: ${theme.accent1};
      border-bottom: 1px solid ${theme.accent1};
  `}
`

const TokenGridWrapper = styled.div`
  display: flex;
  height: 80px;
  padding: 20px;
  padding-bottom: 0px;
  gap: 20px;
`

const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  // background: ${({ theme }) => theme.surface2};
  // border-radius: 16px 16px 0px 0px;
`

const TokenCard = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 16px;
  // background: ${({ theme }) => theme.surface2};
  border-bottom: 1px solid ${({ theme }) => theme.surface3};
  // border-radius: 16px;
  padding: 20px 0;
  transition: all 0.3s ease;
  position: relative;
  // animation: fadeIn 0.5s ease-out;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 10px;

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.accent1};
    color: ${({ theme }) => theme.accent1};
  }

  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: -2px;
  //   left: -2px;
  //   right: -2px;
  //   bottom: -2px;
  //   border-radius: 18px;
  //   z-index: -1;
  //   animation: borderGlow 3s linear infinite;
  //   opacity: 0;
  //   transition: opacity 0.3s ease;
  // }

  // &:hover::before {
  //   opacity: 1;
  // }

  // @keyframes borderBottomGlow {
  //   0% {
  //     background-position: 0% 50%;
  //   }
  //   50% {
  //     background-position: 100% 50%;
  //   }
  //   100% {
  //     background-position: 0% 50%;
  //   }
  // }

  // @keyframes fadeIn {
  //   from {
  //     opacity: 0;
  //     transform: translateY(20px);
  //   }
  //   to {
  //     opacity: 1;
  //     transform: translateY(0);
  //   }
  // }

  // @keyframes shake {
  //   0%,
  //   100% {
  //     transform: translateX(0);
  //   }
  //   10%,
  //   30%,
  //   50%,
  //   70%,
  //   90% {
  //     transform: translateX(-2px);
  //   }
  //   20%,
  //   40%,
  //   60%,
  //   80% {
  //     transform: translateX(2px);
  //   }
  // }

  // &.new-token {
  //   animation: shake 0.5s ease-in-out, fadeIn 0.5s ease-out;
  // }
`

const TokenName = styled.h3`
  font-size: 14px;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;

  // &:after {
  //   content: '';
  //   display: inline-block;
  //   width: 6px;
  //   height: 6px;
  //   background: ${({ theme }) => theme.success};
  //   border-radius: 50%;
  //   animation: blink 2s infinite;
  //   box-shadow: 0 0 10px ${({ theme }) => theme.success};
  //   animation: pulse 2s infinite;
  // }

  // @keyframes blink {
  //   50% {
  //     opacity: 0.5;
  //   }
  // }

  // @keyframes pulse {
  //   0% {
  //     opacity: 1;
  //     transform: scale(1);
  //   }
  //   50% {
  //     opacity: 0.5;
  //     transform: scale(1.5);
  //   }
  //   100% {
  //     opacity: 1;
  //     transform: scale(1);
  //   }
  // }
`

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
  margin-bottom: 20px;
`

const PageNumber = styled.div<{ isActive: boolean }>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ isActive, theme }) => (isActive ? theme.accent1 : theme.surface2)};
  color: ${({ isActive, theme }) => (isActive ? theme.surface2 : 'white')};
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${({ isActive }) => (isActive ? '#0056b3' : '#e0e0e0')};
  }
`

const NavCircle = styled.div<{ disabled: boolean }>`
  // display: ${({ disabled }) => (disabled ? 'none' : 'flex')};
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.surface2};
  color: white;
  font-weight: bold;
  font-size: 16px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  visibility: ${({ disabled }) => (disabled ? 'hidden' : 'visible')};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#e0e0e0' : '#d9d9d9')};
  }
`

const PriceChange = styled.span<{ isPositive: boolean }>`
  color: ${({ isPositive, theme }) => (isPositive ? theme.success : theme.deprecated_accentWarning)};
  font-weight: bold;
  animation: ${({ isPositive }) => (isPositive ? 'slideUp' : 'slideDown')} 0.3s ease;

  @keyframes slideUp {
    from {
      transform: translateY(2px);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes slideDown {
    from {
      transform: translateY(-2px);
    }
    to {
      transform: translateY(0);
    }
  }
`

const TokenImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
`

const TokenInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const TokenStatsDescription = styled.div`
  display: flex;

  color: ${({ theme }) => theme.neutral2};
  // background: ${({ theme }) => theme.surface2};
  // border: 1px solid ${({ theme }) => theme.surface3};
  max-width: 100%;
  border-radius: 12px;
  font-size: 16px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;

  white-space: normal;
`

const TokenStats = styled.div`
  display: flex;
  padding: 8px;
  padding-right: 0px;
  // background: ${({ theme }) => theme.surface2};
  // border: 1px solid ${({ theme }) => theme.surface3};
  overflow: hidden; /* Hides overflowing text */
  white-space: nowrap; /* Prevents wrapping to the next line */
  text-overflow: ellipsis;
  border-radius: 12px;
  width: 100%;
  max-width: 200px;
  text-align: right;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 20px;
`

const TokenStatsFirst = styled.div`
  display: flex;
  padding: 8px;
  color: ${({ theme }) => theme.white};
  // background: ${({ theme }) => theme.surface2};
  width: 200px; /* Set a fixed width */
  // border: 1px solid ${({ theme }) => theme.surface3};
  text-align: right;
  justify-content: flex-end;
  overflow: hidden; /* Hides overflowing text */
  white-space: nowrap; /* Prevents wrapping to the next line */
  text-overflow: ellipsis;
  border-radius: 12px;
  font-size: 14px;
`

const BondingCurveWrapper = styled.div`
  margin-top: 8px;
  padding: 8px;
  background: ${({ theme }) => theme.surface2};
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme }) => theme.surface3};
    transform: scale(1.02);
  }
`

const ProgressStack = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const ProgressLabel = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  color: ${({ theme }) => theme.neutral2};
`

const ProgressBarOuter = styled.div<{ type?: 'success' | 'warning' | 'danger' }>`
  height: 12px; // Smaller than before
  background: rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset;
`

const ProgressBarInner = styled.div<{ progress: number; type?: 'success' | 'warning' | 'danger' }>`
  height: 100%;
  width: ${(props) => props.progress}%;
  background: ${({ progress, theme }) => {
    // Using the theme's accent1 color for success or fallback
    if (progress < 33) {
      return `linear-gradient(90deg, #ff2d55 0%, #ff6b8b 100%)` // Danger with gradient
    } else if (progress < 70) {
      return `linear-gradient(90deg, #ff8a00 0%, #ffc107 100%)` // Warning with gradient
    } else if (progress < 99.999) {
      return `linear-gradient(90deg, #47d794 0%, #3cc5d7 100%)` // Green with gradient
    } else if (progress >= 99.999) {
      return `linear-gradient(90deg, ${theme.accent1} 0%, #60efff 100%)` // Success with accent1 color
    } else {
      return `linear-gradient(90deg, ${theme.accent1} 0%, #60efff 100%)` // Default fallback using accent1
    }
  }};
  transition: width 0.3s ease; // Smooth transition effect for progress
`

const LiquidityInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  font-size: 12px;
  color: ${({ theme }) => theme.neutral2};
`

const AddLiquidityButton = styled.a`
  color: ${({ theme }) => theme.accent1};
  text-decoration: none;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 4px;
  background: ${({ theme }) => `${theme.accent1}11`};
  transition: all 0.2s;

  &:hover {
    background: ${({ theme }) => `${theme.accent1}22`};
  }
`

const TokenHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px 60px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`

const StyledInput = styled.input`
  padding: 10px;
  border-radius: 8px;
  color: white;
  background: ${({ theme }) => theme.surface2};
  border: 1px solid ${({ theme }) => theme.surface3};
  width: 100%;
  max-width: 400px;

  &:hover {
    border: none; /* Removes the border on hover */
  }

  &:focus {
    outline: none; /* Optionally remove outline on focus */
  }
`

const ButtonPrimaryStyled = styled(ButtonPrimary)`
  // background: ${({ theme }) => theme.accent1};
  color: ${({ theme }) => theme.surface2};
  // border: 1px solid ${({ theme }) => theme.surface2};

  border-radius: 12px;
  // font-weight: bold;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05);
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);

    // ${({ theme }) => neonGlow(theme.surface2)}
  }
`

const ButtonStyled = styled(ButtonSecondary)`
  // background: ${({ theme }) => theme.accent1};
  // color: ${({ theme }) => theme.black};
  border: 1px solid ${({ theme }) => theme.surface3};
  border-radius: 12px;
  // width: 200px;
  padding: 10px;
  // text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  // font-weight: bold;
  align-items: center;
  // &:hover {
  //   background: ${({ theme }) => theme.surface2};
  // }
`
const LaunchModal = styled.div`
  padding: 24px;
  padding-top: 0px;
  background: ${({ theme }) => theme.surface1};
  border-radius: 20px;
  width: 100%;
  height: 100%;
  max-width: 500px;
`

const LaunchContainer = styled.div`
  padding: 24px;
  background: ${({ theme }) => theme.surface1};
  // border-radius: 20px;
  width: 100%;
  // max-width: 500px;
`

const RandomCoinCard = styled(Card)`
  background: ${({ theme }) => theme.surface2};
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.accent1}33;
  ${({ theme }) => neonGlow(theme.accent1)}
  height: 300px;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    height: 100%;
    max-width: 100vw;
  }

  &:hover {
    transform: translateY(-4px) scale(1.02);
    ${({ theme }) => neonGlow(theme.accent1 + '40')}
    border-color: ${({ theme }) => theme.accent1};
  }

  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: 18px;

    z-index: -1;
    animation: borderGlow 3s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::before {
    opacity: 1;
  }

  @keyframes borderGlow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes shake {
    0%,
    100% {
      transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
      transform: translateX(-2px);
    }
    20%,
    40%,
    60%,
    80% {
      transform: translateX(2px);
    }
  }
  transition: all 0.3s ease;
  animation: fadeIn 0.5s ease-out;

  animation: shake 0.5s ease-in-out, fadeIn 0.5s ease-out;
`

const CoinImage = styled.div`
  // border: 1px solid ${({ theme }) => theme.accent1}33;
  background: transparent;
  background-color: none;
`

const CoinWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 500px;
  height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    flex-direction: column;
    align-items: center;
    height: 100%;
    max-width: 100vw;
  }
`

const CoinWrapperSecondary = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  min-width: 0;
  height: 70px;
  overflow: hidden;
  padding: 0 20px;
  text-overflow: ellipsis;
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    flex-direction: row;
    align-items: center;
    height: 100%;
    max-width: 100%;
  }
`

const CoinInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  line-height: 1.2;
  max-width: 400px;
  overflow: hidden;
`

const CoinInfoSecondary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  line-height: 1.2;
  overflow: hidden;
  width: 100%;
  min-width: 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    height: 70px;
  }
`

const CoinStats = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
  margin: 16px;
  margin-top: 0px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    flex-direction: column;
    height: 100%;
    max-width: 100vw;
  }
`

const CoinContainer = styled.div`
  display: flex;
  height: 110px;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.surface1};
  padding: 20px 40px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    flex-direction: column;
    height: 100%;
    max-width: 100vw;
    padding: 10px 0;
  }
`

const CoinHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 24px;
  align-self: flex-start;
  padding: 0;
  margin: 0;
  text-align: start;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  max-width: 150px;
`

const CoinHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  overflow: hidden;
`

const CoinHeaderContent = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 24px;
  color: ${({ theme }) => theme.neutral2};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  max-width: 100px;
`

const CoinContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
  font-size: 12px;
  color: ${({ theme }) => theme.neutral2};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  width: 100%;
`

const HeaderCoin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.accent1};

  // width: 100%;
  font-size: 26px;
  font-weight: bold;
`

const StatItem = styled.div`
  background: ${({ theme }) => theme.surface1};
  padding: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  min-width: 100px;
  border-radius: 12px;

  > span:first-child {
    color: ${({ theme }) => theme.accent1};
    font-size: 14px;
    display: block;
    margin-bottom: 4px;
  }

  > span:last-child {
    font-size: 20px;
    // font-weight: bold;
  }
`

const Title = styled.div`
  text-align: center;
  font-size: 24px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.accent1};
`

const ImageEmpty = styled.div`
  border: 1px solid ${({ theme }) => theme.surface3};
  background: ${({ theme }) => theme.surface2};

  text-align: center;
  align-items: center;
`

const ImageLaunch = styled.img`
  border: 1px solid ${({ theme }) => theme.surface3};
  background: ${({ theme }) => theme.surface2};
`

const ResizableDiv = styled.textarea`
  background: ${({ theme }) => theme.surface2};

  border: 1px solid ${({ theme }) => theme.surface3};
  border-radius: 12px;
  padding: 10px;
  min-height: 120px;
  resize: vertical;
  overflow: auto;
  font-size: 14px;
  line-height: 1.4;
  text-align: left;
  vertical-align: top;
  padding: 12px;
  color: ${({ theme }) => theme.neutral1};

  &:focus {
    outline: none;
  }

  ${ScrollBarStyles}

  scrollbar-gutter: stable;
  overscroll-behavior: contain;
`

const LaunchForm = ({ onDismiss }: { onDismiss: () => void }) => {
  const { chainId } = useWeb3React()
  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID
  const [tokenName, setTokenName] = useState('')
  const [tokenSymbol, setTokenSymbol] = useState('')
  const [tokenDescription, setTokenDescription] = useState('')
  const [tokenImage, setTokenImage] = useState('')
  const [initialBuy, setInitialBuy] = useState<number>(0)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [fileUploadSuccess, setFileUploadSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [showReviewModal, setShowReviewModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | undefined>()
  const [showSocialLinks, setShowSocialLinks] = useState(false)
  const [socialLinks, setSocialLinks] = useState({
    website: '',
    discord: '',
    telegram: '',
    x: '',
  })

  const submitTrue = useMemo(() => {
    return (
      tokenName.trim() !== '' &&
      tokenSymbol.trim() !== '' &&
      tokenDescription.trim() !== '' &&
      tokenImage.trim() !== '' &&
      initialBuy !== undefined &&
      initialBuy >= 0 &&
      error === ''
    )
  }, [tokenName, tokenSymbol, tokenDescription, tokenImage, initialBuy, error])

  const handleSubmission = useCallback(async () => {
    try {
      setIsLoading(true)
      setFileUploadSuccess(false)

      const upload = await pinata.upload.file(selectedFile)
      console.log(upload)

      setTokenImage(upload.IpfsHash)
      setFileUploadSuccess(true)
    } catch (error) {
      setError('Error submitting token image.')
    } finally {
      setIsLoading(false)
    }
  }, [selectedFile])

  useEffect(() => {
    if (selectedFile) {
      handleSubmission()
    }
  }, [selectedFile, handleSubmission])

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(event.target?.files?.[0])
  }

  const { provider } = useWeb3React()
  const LaunchpadContract = SpookyLaunchpad()
  const addTransaction = useTransactionAdder()

  const [transaction, setTransaction] = useState<string>('') // State to store the transaction

  const handleSubmit = async () => {
    try {
      if (!tokenName || !tokenSymbol || !tokenDescription || !tokenImage || !provider || initialBuy < 0) {
        throw new Error('Please fill out all fields.')
      }

      const initialBuyInWei = parseEther(initialBuy.toString())
      let totalValue = parseEther(initialBuy.toString())
      totalValue = initialBuyInWei.add(parseEther(CREATION_PRICE_IN_ETHER))

      const links: string[] = []
      if (socialLinks.website) links.push(socialLinks.website)
      if (socialLinks.discord) links.push(socialLinks.discord)
      if (socialLinks.telegram) links.push(socialLinks.telegram)
      if (socialLinks.x) links.push(socialLinks.x)

      const signer = provider.getSigner()

      const estimatedGas = await LaunchpadContract?.estimateGas.create(
        tokenName,
        tokenSymbol,
        tokenImage,
        tokenDescription,
        links,
        0,
        0,
        initialBuyInWei,
        {
          value: totalValue,
        }
      )

      if (estimatedGas) {
        setTransaction('Sending transaction...')
        const tx = await LaunchpadContract?.connect(signer).create(
          tokenName,
          tokenSymbol,
          tokenImage,
          tokenDescription,
          links,
          0,
          0,
          initialBuyInWei,
          {
            gasLimit: calculateGasMargin(estimatedGas),
            value: totalValue,
          }
        )
        // .then((response: any) => {
        //   const description = `Launch Coin`

        //   addTransaction(response, {
        //     type: TransactionType.LAUNCH_TOKEN,
        //     summary: description,
        //   })
        // })

        if (tx) {
          const receipt = await tx.wait() // Wait for transaction to be mined
          if (receipt?.status === 1) {
            setTransaction('Transaction successful!')
          } else {
            setTransaction('Transaction failed.')
          }
        }

        // setSuccess('Token launched successfully!')
        // onDismiss()
      } else {
        throw new Error('Failed to estimate gas')
      }
    } catch (err: any) {
      setTransaction('Error occurred during transaction.')
      error
    }
  }

  const isValidDiscordLink = (url: string) => {
    const regex = /^(https:\/\/)?(www\.)?discord\.(gg|com)\/(?:invite\/)?[a-zA-Z0-9-]+$/
    return url === '' || regex.test(url)
  }

  const isValidTelegramLink = (url: string) => {
    const regex = /^(https:\/\/)?(www\.)?(t\.me|telegram\.me|telegram\.org)\/[a-zA-Z0-9_]+(?:\/[a-zA-Z0-9_]+)?$/
    return url === '' || regex.test(url)
  }

  const isValidXLink = (url: string) => {
    return true
    // const regex = /^(https?:\/\/)?(www\.)?(x|twitter)\.com\/[a-zA-Z0-9_]+$/
    // return url === '' || regex.test(url)
  }

  const isValidWebsiteLink = (url: string) => {
    const regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9#?&%=._-]*)?$/
    return url === '' || regex.test(url)
  }

  const handleSocialChange = (e: React.ChangeEvent<HTMLInputElement>, key: keyof typeof socialLinks) => {
    const updatedValue = e.target.value
    setSocialLinks((prevSocialLinks) => {
      const updatedSocialLinks = { ...prevSocialLinks, [key]: updatedValue }

      let errorMessage = ''

      if (updatedSocialLinks.discord && !isValidDiscordLink(updatedSocialLinks.discord)) {
        errorMessage = 'Please enter a valid Discord link.'
      } else if (updatedSocialLinks.telegram && !isValidTelegramLink(updatedSocialLinks.telegram)) {
        errorMessage = 'Please enter a valid Telegram link.'
      } else if (updatedSocialLinks.x && !isValidXLink(updatedSocialLinks.x)) {
        errorMessage = 'Please enter a valid X link.'
      } else if (updatedSocialLinks.website && !isValidWebsiteLink(updatedSocialLinks.website)) {
        errorMessage = 'Please enter a valid Website link.'
      } else {
        errorMessage = ''
      }

      setError(errorMessage)

      return updatedSocialLinks
    })
  }

  const handleSubmit2 = async () => {
    setShowReviewModal(true)
  }

  return (
    <LaunchModal>
      {showReviewModal ? (
        <>
          <ReviewModal
            tokenName={tokenName}
            tokenSymbol={tokenSymbol}
            tokenDescription={tokenDescription}
            tokenImage={tokenImage}
            initialBuy={initialBuy}
            socialLinks={socialLinks}
            isLoading={isLoading}
            transactionStatus={transaction}
            onLaunch={handleSubmit}
            onCancel={() => setShowReviewModal(false)}
          />
        </>
      ) : (
        <LaunchContainer>
          <Title>Launch Your Coin</Title>

          <AutoColumn gap="16px">
            Token Name
            <Input placeholder="Token Name" value={tokenName} onChange={(e) => setTokenName(e.target.value)} />
            Token Symbol
            <Input placeholder="Token Symbol" value={tokenSymbol} onChange={(e) => setTokenSymbol(e.target.value)} />
            {/* <Input
          placeholder="Token Description"
          value={tokenDescription}
          onChange={(e) => setTokenDescription(e.target.value)}
        /> */}
            <>
              Token Description
              <ResizableDiv
                placeholder="Token Description"
                onChange={(e) => {
                  const value = e.target.value
                  if (value.length <= 500) {
                    setTokenDescription(value)
                  }
                }}
                value={tokenDescription}
              />
              {tokenDescription.length === 500 && (
                <div style={{ fontSize: '12px', color: '#888' }}>{tokenDescription.length} / 500 characters</div>
              )}
            </>
            <>
              <RowBetween>
                <AutoColumn style={{ height: '92px', width: '200px' }}>
                  <label className="form-label"> Choose Token Image File</label>

                  <input
                    type="file"
                    onChange={changeHandler}
                    accept=".png, .jpeg, .gif, .jpg"
                    style={{ display: 'flex', flexDirection: 'column', maxWidth: '200px', wordWrap: 'break-word' }}
                  />
                </AutoColumn>

                {fileUploadSuccess ? (
                  <>
                    <ImageLaunch
                      src={`https://gateway.pinata.cloud/ipfs/${tokenImage}`}
                      alt="Token"
                      style={{ width: '92px', height: '92px' }}
                    />
                  </>
                ) : (
                  <ImageEmpty style={{ minWidth: '92px', height: '92px' }}>
                    {isLoading ? (
                      <>
                        <Img src="https://assets.spooky.fi/grimSpinner2.gif" width={92} height={92} />
                      </>
                    ) : (
                      ''
                    )}
                  </ImageEmpty>
                )}
              </RowBetween>
              {/* <ButtonStyled onClick={handleSubmission} disabled={isLoading || !selectedFile}>
                {isLoading ? 'Submitting...' : 'Submit Token Image'}
              </ButtonStyled>{' '} */}
            </>
            <Row>
              Initial Buy <TextOptional>&nbsp;(Optional)</TextOptional>
            </Row>
            <InputWrapper>
              <Input
                placeholder="Initial Buy"
                type="number"
                value={initialBuy}
                onChange={(e) => setInitialBuy(parseFloat(e.target.value))}
              />
              <InputText>
                <CurrencyLogo currency={nativeOnChain(chainIdOrDefault)}></CurrencyLogo>
                {NATIVE_CURRENCY[chainIdOrDefault]}
              </InputText>
            </InputWrapper>
            <RowBetween style={{ display: 'flex', alignItems: 'left' }}>
              <ButtonStyled onClick={() => setShowSocialLinks((prev) => !prev)}>
                {showSocialLinks ? (
                  <>
                    Hide Social Links<ArrowUp></ArrowUp>
                  </>
                ) : (
                  <>
                    Add Social Links (Optional)<ArrowDown></ArrowDown>
                  </>
                )}
              </ButtonStyled>
            </RowBetween>
            {showSocialLinks && (
              <AutoColumn gap="8px">
                Website
                <Input
                  placeholder="Website"
                  value={socialLinks.website}
                  onChange={(e) => handleSocialChange(e, 'website')}
                />
                Discord
                <Input
                  placeholder="Discord"
                  value={socialLinks.discord}
                  onChange={(e) => handleSocialChange(e, 'discord')}
                />
                Telegram
                <Input
                  type="text"
                  placeholder="Telegram"
                  value={socialLinks.telegram}
                  onChange={(e) => handleSocialChange(e, 'telegram')}
                />
                X (Twitter)
                <Input placeholder="X (Twitter)" value={socialLinks.x} onChange={(e) => handleSocialChange(e, 'x')} />
              </AutoColumn>
            )}
            <ButtonPrimaryStyled onClick={handleSubmit2} disabled={!submitTrue}>
              Launch Token
            </ButtonPrimaryStyled>
          </AutoColumn>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {success && <p style={{ color: 'green' }}>{success}</p>}
        </LaunchContainer>
      )}
    </LaunchModal>
  )
}

const ReviewModal = ({
  tokenName,
  tokenSymbol,
  tokenDescription,
  tokenImage,
  initialBuy,
  socialLinks,
  isLoading,
  transactionStatus,
  onLaunch,
  onCancel,
}: {
  tokenName: string
  tokenSymbol: string
  tokenDescription: string
  tokenImage: string
  initialBuy: number
  socialLinks: { [key: string]: string }
  isLoading: boolean
  transactionStatus: string
  onLaunch: () => void
  onCancel: () => void
}) => {
  const { chainId } = useWeb3React()
  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID
  const theme = useTheme()
  return (
    <>
      <LaunchContainer>
        {transactionStatus === 'Transaction successful!' && (
          <>
            <AutoColumn>
              <AdvancedHeader>Token Creation Success</AdvancedHeader>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Img src="https://assets.spooky.fi/happy_grim.gif" width={227} />
                <div style={{ color: theme.success }}>Token Creation successful!</div>
              </div>
            </AutoColumn>
          </>
        )}
        {(transactionStatus === 'Error occurred during transaction.' || transactionStatus === 'Transaction failed') && (
          <>
            <AutoColumn>
              <AdvancedHeader>Token Creation Cancelled</AdvancedHeader>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Img src="https://assets.spooky.fi/icons/404.png" width={227} />
                <div style={{ color: theme.critical }}>Error occured during transaction.</div>
              </div>
            </AutoColumn>
          </>
        )}
        {transactionStatus === 'Sending transaction...' && (
          <>
            <AutoColumn>
              <AdvancedHeader>Check Wallet...</AdvancedHeader>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Img src="https://assets.spooky.fi/grimSpinner2.gif" width={227} />
                <div style={{ color: theme.neutral3 }}>Proceed to your wallet</div>
              </div>
            </AutoColumn>
          </>
        )}
        {!transactionStatus && (
          <>
            <Title>Review Launch Details</Title>
            <AutoColumn gap="10px" color={theme.accent1} style={{ marginTop: '20px' }}>
              <RowBetween>
                <img
                  src={`https://gateway.pinata.cloud/ipfs/${tokenImage}`}
                  alt="Token"
                  style={{ width: '100px', height: '100px' }}
                />
                <AutoColumn>
                  <TokenStats>{tokenName}</TokenStats>
                  <TokenStats>{tokenSymbol}</TokenStats>
                </AutoColumn>
              </RowBetween>

              <TokenStatsDescription>{tokenDescription}</TokenStatsDescription>
              <RowBetween style={{ borderBottom: '1px solid grey' }}>
                Initial Buy{' '}
                <TokenStats style={{ alignItems: 'center', gap: '10px' }}>
                  {initialBuy} <CurrencyLogo currency={nativeOnChain(chainIdOrDefault)}></CurrencyLogo>
                </TokenStats>
              </RowBetween>
              <RowBetween style={{ color: theme.accent1 }}>
                Website <TokenStatsFirst>{socialLinks.website !== '' ? socialLinks.website : '-'}</TokenStatsFirst>
              </RowBetween>
              <RowBetween style={{ color: theme.accent1 }}>
                Discord <TokenStatsFirst>{socialLinks.discord !== '' ? socialLinks.discord : '-'}</TokenStatsFirst>
              </RowBetween>
              <RowBetween style={{ color: theme.accent1 }}>
                Telegram{' '}
                <TokenStatsFirst style={{ textOverflow: 'ellipsis' }}>
                  {socialLinks.telegram !== '' ? socialLinks.telegram : '-'}
                </TokenStatsFirst>
              </RowBetween>
              <RowBetween style={{ color: theme.accent1 }}>
                X (Twitter) <TokenStatsFirst>{socialLinks.x !== '' ? socialLinks.x : '-'}</TokenStatsFirst>
              </RowBetween>
              <div>
                <ButtonPrimaryStyled style={{ marginTop: '10px' }} onClick={onLaunch} disabled={isLoading}>
                  {isLoading ? 'Launching...' : 'Confirm Launch'}
                </ButtonPrimaryStyled>
                <ButtonStyled style={{ marginTop: '10px' }} onClick={onCancel}>
                  Go Back
                </ButtonStyled>
              </div>
            </AutoColumn>
          </>
        )}
      </LaunchContainer>
    </>
  )
}

export default function Launchpad() {
  const { wnativePrice } = useWNativeUSDPrice()
  const { chainId } = useWeb3React()
  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID
  const nativeTokenName = nativeOnChain(chainIdOrDefault).symbol
  const activities = useGetRecentSwaps(10)
  const LaunchpadContract = SpookyLaunchpad()
  const { tokensFromPools, refetch: refetchTokensFromPools } = useLaunchpadTokensFromPools()
  const [showLaunchModal, setShowLaunchModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const ITEMS_PER_PAGE = 15
  const [showBonded, setShowBonded] = useState(false)
  const [showUSDPrice, setShowUSDPrice] = useState(true)

  // Add polling interval to refetch data
  useEffect(() => {
    const interval = setInterval(() => {
      activities.refetch()
      refetchTokensFromPools()
    }, 2000) // Poll every 2 seconds

    return () => clearInterval(interval)
  }, [activities, refetchTokensFromPools])

  // Keep the event listeners for immediate updates
  useEffect(() => {
    if (!LaunchpadContract) return

    const handleSwap = async (
      sender: string,
      amount0In: any,
      amount1In: any,
      amount0Out: any,
      amount1Out: any,
      to: string
    ) => {
      console.log('New swap detected, refreshing data...')
      activities.refetch()
      refetchTokensFromPools()
    }

    const handleTokenCreated = async (creator: string, token: string) => {
      console.log('New token created, refreshing data...')
      refetchTokensFromPools()
      // Add the new token to the set
      setNewTokens((prev) => new Set([...prev, token.toLowerCase()]))
      // Remove the token from the new tokens set after animation
      setTimeout(() => {
        setNewTokens((prev) => {
          const updated = new Set(prev)
          updated.delete(token.toLowerCase())
          return updated
        })
      }, 5000) // Remove after 5 seconds
    }

    LaunchpadContract.on('Swap', handleSwap)
    LaunchpadContract.on('TokenCreated', handleTokenCreated)

    return () => {
      LaunchpadContract.off('Swap', handleSwap)
      LaunchpadContract.off('TokenCreated', handleTokenCreated)
    }
  }, [LaunchpadContract, activities, refetchTokensFromPools])

  const handleDismissModal = useCallback(() => setShowLaunchModal(false), [])
  //populate top tokens
  const topToken: any[] = []
  const bondedTokens: any[] = []
  let counter = 0
  let _token
  while (topToken.length != 3) {
    _token = tokensFromPools[counter++]
    if (_token == undefined) break
    if (_token.tokenCreated.fakePoolMCapReacheds.length == 0) {
      topToken.push(_token)
    }
  }

  for (let i = 0; i < tokensFromPools.length; i++) {
    const _token = tokensFromPools[i]
    if (_token == undefined) break
    if (_token.tokenCreated.fakePoolMCapReacheds.length !== 0) {
      bondedTokens.push(_token)
    }
  }

  const [selectedHeader, setSelectedHeader] = useState('')
  const [isSelected, setIsSelected] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  useEffect(() => {
    if (searchQuery !== '') {
      setIsSelected(true)
    } else {
      setIsSelected(false)
    }
  }, [searchQuery])

  const filteredTokens = tokensFromPools
    .filter(
      (token) =>
        token.tokenCreated.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        token.tokenCreated.symbol.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter(
      (token) => token.tokenCreated.tokenLauncheds.length == 0 //TODO Move this filter to graphql query
    )

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE

  const totalPages = showBonded
    ? Math.ceil(bondedTokens.length / ITEMS_PER_PAGE)
    : Math.ceil(filteredTokens.length / ITEMS_PER_PAGE)

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const goToNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1)
  }

  const goToPreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1)
  }

  const goToFirstPage = () => {
    setCurrentPage(1)
  }

  const goToLastPage = () => {
    setCurrentPage(totalPages)
  }

  const navigate = useNavigate()

  const handleCardClick = (token: string) => {
    navigate(`/launchpad/${token}`)
  }

  const { volume } = useGet24hVolume(
    topToken[0]?.tokenCreated.token || '0',
    Math.floor(Date.now() / 1000) - 24 * 60 * 60
  )

  const theme = useTheme()

  const handleHeaderClick = (header: SetStateAction<string>) => {
    setSelectedHeader(header)
    setIsSelected(true)
  }

  const [newTokens, setNewTokens] = useState<Set<string>>(new Set())

  const selectedTokens =
    selectedHeader === HeaderType.MarketCap
      ? [...filteredTokens]
      : [...filteredTokens].sort((a, b) => {
          if (selectedHeader === HeaderType.Newest) {
            return b.tokenCreated.blockTimestamp - a.tokenCreated.blockTimestamp
          } else if (selectedHeader === HeaderType.Bump) {
            return bumpSort(a, b)
          }
          return 0
        })

  const currentTokens = showBonded
    ? bondedTokens.slice(startIndex, startIndex + ITEMS_PER_PAGE)
    : selectedTokens.slice(startIndex, startIndex + ITEMS_PER_PAGE)

  function bumpSort(a, b) {
    if (b.swaps.length == 0 && a.swaps.length == 0) return 0
    if (b.swaps.length == 0) return -1
    if (a.swaps.length == 0) return 1
    return b.swaps[0].blockTimestamp - a.swaps[0].blockTimestamp
  }

  const tokensToShow = filteredTokens.slice(0, 5)
  const sortedTokens = filteredTokens.sort((b, a) => a.tokenCreated.blockTimestamp - b.tokenCreated.blockTimestamp)
  const sortedShowTokens = sortedTokens.slice(0, 5)
  const sortedTokensBump = filteredTokens.sort(bumpSort)
  const sortedShowTokensBump = sortedTokensBump.slice(0, 5)

  const columns = [tokensToShow, sortedShowTokens, sortedShowTokensBump]

  const handleViewMoreClick = (columnIndex: number) => {
    if (columnIndex === 0) {
      handleHeaderClick(HeaderType.MarketCap)
    } else if (columnIndex === 1) {
      handleHeaderClick(HeaderType.Newest)
    } else if (columnIndex === 2) {
      handleHeaderClick(HeaderType.Bump)
    }
  }

  return (
    <>
      <Modal isOpen={showLaunchModal} onDismiss={handleDismissModal}>
        <LaunchForm onDismiss={handleDismissModal} />
      </Modal>

      <HeaderWrapper>
        <SwitchWrapper>
          <GenericToggle
            rangeOption={showUSDPrice}
            text1="USD"
            text2={nativeTokenName == undefined ? 'Native' : nativeTokenName}
            handleRangeToggle={() => {
              setShowUSDPrice(!showUSDPrice)
            }}
          />
        </SwitchWrapper>
        <LaunchpadWrapper>
          <ActivityCard>
            Recent Activity
            {activities.recentSwaps.map((activity) => (
              <ActivityItem key={activity.transactionHash} type={activity.amount0In === '0' ? 0 : 1}>
                <ActivityContent>
                  <img style={{ width: '30px' }} src={activity.amount0In === '0' ? magicpete : magicpog} />
                  <ActivityBadge type={activity.amount0In === '0' ? 0 : 1}>
                    {activity.amount0In === '0' ? 'SOLD' : 'BOUGHT'}
                  </ActivityBadge>
                  <Amount>
                    {showUSDPrice && '$'}
                    {activity.amount0In === '0'
                      ? numberWithCommas(((activity.amount0Out * (showUSDPrice ? wnativePrice : 1)) / 1e18).toFixed(2))
                      : numberWithCommas(((activity.amount0In * (showUSDPrice ? wnativePrice : 1)) / 1e18).toFixed(2))}
                    {!showUSDPrice && ' ' + nativeTokenName}
                  </Amount>
                  <Amount>{'of ' + activity.pool.tokenCreated.symbol}</Amount>
                  {/*<AssetLogo chainId={250} address={activity.pool.token} size="16px" hideL2Icon={false} />*/}
                </ActivityContent>
                {/*<small>{activity.blockTimestamp}</small>*/}
              </ActivityItem>
            ))}
          </ActivityCard>
          <Chatbox tokensFromPools={tokensFromPools}></Chatbox>
          {topToken[0] != undefined && (
            <RandomCoinCard onClick={() => handleCardClick(topToken[0].tokenCreated.token)}>
              <HeaderCoin>King of the Haunted Hill</HeaderCoin>

              <CoinContainer>
                {topToken[0] != undefined ? (
                  <CoinWrapper>
                    <CoinImage style={{ width: '70px', height: '70px', marginRight: '20px' }}>
                      {' '}
                      <img
                        src={'https://harlequin-tiny-dove-45.mypinata.cloud/ipfs/' + topToken[0].tokenCreated.imageCid}
                        height="70px"
                        width="70px"
                      />{' '}
                    </CoinImage>
                    {/* <RandomAvatar name={tokensFromPools[0].tokenCreated.name} square={true} size={60} />{' '} */}
                    <CoinInfo>
                      <CoinHeaderContainer>
                        <CoinHeader>{topToken[0].tokenCreated.name}</CoinHeader>
                        <CoinHeaderContent>{topToken[0].tokenCreated.symbol}</CoinHeaderContent>
                      </CoinHeaderContainer>
                      <CoinContent>{topToken[0].tokenCreated.description}</CoinContent>
                    </CoinInfo>
                    {/* <CoinHeader>{tokensFromPools[0].tokenCreated.name}</CoinHeader>
                <CoinHeader>{'[' + tokensFromPools[0].tokenCreated.symbol + ']'}</CoinHeader>
                <CoinStats>
                  <StatItem>
                    <span>Market Cap</span>
                    <span>
                      $
                      {(tokensFromPools[0].price * tokensFromPools[0].tokenCreated.supply) /
                        1000000000000000000000000000000000000}
                    </span>
                  </StatItem>
                </CoinStats> */}
                  </CoinWrapper>
                ) : (
                  ''
                )}
              </CoinContainer>
              <CoinStats>
                {topToken[0] != undefined ? (
                  <>
                    <StatItem>
                      <span>24h Volume</span>
                      <span>{formatVolume(volume, wnativePrice, showUSDPrice, nativeTokenName)}</span>
                    </StatItem>
                    <StatItem>
                      <span>Market Cap</span>
                      <span>{formatMarketCap(topToken[0].price, wnativePrice, showUSDPrice, nativeTokenName)}</span>
                    </StatItem>
                    <StatItem>
                      <span>Created By</span>
                      <span>
                        {topToken[0]?.tokenCreated?.creator
                          ? `${topToken[0].tokenCreated.creator.substring(
                              0,
                              5
                            )}...${topToken[0].tokenCreated.creator.slice(-5)}`
                          : ''}
                      </span>
                    </StatItem>
                  </>
                ) : (
                  <></>
                )}
              </CoinStats>
            </RandomCoinCard>
          )}
          <PepeCard>
            <TrendingToken
              style={{ marginBottom: '10px' }}
              onClick={() => handleCardClick(topToken[1].tokenCreated.token)}
            >
              <TrendingTokenSpotlight>#2 Spotlight</TrendingTokenSpotlight>
              {topToken[1] != undefined ? (
                <CoinWrapperSecondary>
                  <CoinImage style={{ width: '70px', height: '70px', marginRight: '20px' }}>
                    {' '}
                    <img
                      src={'https://harlequin-tiny-dove-45.mypinata.cloud/ipfs/' + topToken[1].tokenCreated.imageCid}
                      height="70px"
                      width="70px"
                    />{' '}
                  </CoinImage>
                  {/* <RandomAvatar name={tokensFromPools[0].tokenCreated.name} square={true} size={60} />{' '} */}
                  <CoinInfoSecondary>
                    <CoinHeaderContainer>
                      <CoinHeader>{topToken[1].tokenCreated.name}</CoinHeader>
                      <CoinHeaderContent>{topToken[1].tokenCreated.symbol}</CoinHeaderContent>
                    </CoinHeaderContainer>
                    <CoinContent>{topToken[1].tokenCreated.description}</CoinContent>
                  </CoinInfoSecondary>
                  {/* <CoinHeader>{tokensFromPools[0].tokenCreated.name}</CoinHeader>
                <CoinHeader>{'[' + tokensFromPools[0].tokenCreated.symbol + ']'}</CoinHeader>
                <CoinStats>
                  <StatItem>
                    <span>Market Cap</span>
                    <span>
                      $
                      {(tokensFromPools[0].price * tokensFromPools[0].tokenCreated.supply) /
                        1000000000000000000000000000000000000}
                    </span>
                  </StatItem>
                </CoinStats> */}
                </CoinWrapperSecondary>
              ) : (
                ''
              )}
              {topToken[1] != undefined ? (
                <ProgressStack style={{ margin: '0 20px' }}>
                  <div>
                    <ProgressLabel>
                      <span>Market Cap</span>
                      <span>{formatMarketCap(topToken[1].price, wnativePrice, showUSDPrice, nativeTokenName)}</span>
                    </ProgressLabel>
                    <ProgressBarOuter type="success">
                      <ProgressBarInner
                        progress={
                          /*       100 * (mcap - startMcap) / deltaMcapRequired    */
                          (100 * ((topToken[1].price * 1000000000) / 1e18 - (STARTING_MCAP_ETHER - 0.001))) /
                          DELTA_MCAP_REQUIRED
                        }
                        type="success"
                      />
                    </ProgressBarOuter>
                  </div>
                </ProgressStack>
              ) : (
                ''
              )}
            </TrendingToken>
            <TrendingToken onClick={() => handleCardClick(topToken[2].tokenCreated.token)}>
              <TrendingTokenSpotlight>#3 Spotlight</TrendingTokenSpotlight>
              {topToken[2] != undefined ? (
                <CoinWrapperSecondary>
                  <CoinImage style={{ width: '70px', height: '70px', marginRight: '20px' }}>
                    {' '}
                    <img
                      src={'https://harlequin-tiny-dove-45.mypinata.cloud/ipfs/' + topToken[2].tokenCreated.imageCid}
                      height="70px"
                      width="70px"
                    />{' '}
                  </CoinImage>
                  {/* <RandomAvatar name={tokensFromPools[0].tokenCreated.name} square={true} size={60} />{' '} */}
                  <CoinInfoSecondary>
                    <CoinHeaderContainer>
                      <CoinHeader>{topToken[2].tokenCreated.name}</CoinHeader>
                      <CoinHeaderContent>{topToken[2].tokenCreated.symbol}</CoinHeaderContent>
                    </CoinHeaderContainer>
                    <CoinContent>{topToken[2].tokenCreated.description}</CoinContent>
                  </CoinInfoSecondary>
                  {/* <CoinHeader>{tokensFromPools[0].tokenCreated.name}</CoinHeader>
              <CoinHeader>{'[' + tokensFromPools[0].tokenCreated.symbol + ']'}</CoinHeader>
              <CoinStats>
                <StatItem>
                  <span>Market Cap</span>
                  <span>${formatWeiToDecimal(BigNumber.from(topToken[0].price * WNATIVE_USD_PRICE).mul(1000000000).toHexString())}</span>
                </StatItem>
                <StatItem>
                  <span>Created By</span>
                  <span>
                    $
                    {(tokensFromPools[0].price * tokensFromPools[0].tokenCreated.supply) /
                      1000000000000000000000000000000000000}
                  </span>
                </StatItem>
              </CoinStats> */}
                </CoinWrapperSecondary>
              ) : (
                ''
              )}
              {topToken[2] != undefined ? (
                <ProgressStack style={{ margin: '0 20px' }}>
                  <div>
                    <ProgressLabel>
                      <span>Market Cap</span>
                      <span>{formatMarketCap(topToken[2].price, wnativePrice, showUSDPrice, nativeTokenName)}</span>
                    </ProgressLabel>
                    <ProgressBarOuter type="success">
                      <ProgressBarInner
                        progress={
                          /*       100 * (mcap - startMcap) / deltaMcapRequired    */
                          (100 * ((topToken[2].price * 1000000000) / 1e18 - (STARTING_MCAP_ETHER - 0.001))) /
                          DELTA_MCAP_REQUIRED
                        }
                        type="success"
                      />
                    </ProgressBarOuter>
                  </div>
                </ProgressStack>
              ) : (
                ''
              )}
            </TrendingToken>
          </PepeCard>
        </LaunchpadWrapper>
      </HeaderWrapper>

      <ButtonPrimaryStyled style={{ marginTop: '20px', maxWidth: '1200px' }} onClick={() => setShowLaunchModal(true)}>
        Launch Your Own Coin
      </ButtonPrimaryStyled>

      <TokenGridSection>
        <ContentHeader>
          <TokenHeader>
            <Row gap="md">
              <GenericToggle
                rangeOption={!showBonded}
                text1="Active"
                text2="Launched"
                handleRangeToggle={() => {
                  if (!showBonded) {
                    setIsSelected(true)
                  } else {
                    setIsSelected(false)
                  }
                  setShowBonded(!showBonded)
                  setSelectedHeader('')
                }}
              />
            </Row>
            <Row gap="md" justify="flex-end">
              {/* <HeaderCoin style={{ margin: '0', borderBottom: `1px solid ${theme.accent1}` }}>Trending</HeaderCoin>
            <HeaderCoin style={{ margin: '0', color: 'white' }}>Newest</HeaderCoin> */}
              <StyledInput
                type="text"
                placeholder="Search for a token..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{
                  padding: '10px',
                  borderRadius: '8px',
                  color: 'white',
                  background: theme.surface2,
                  border: `1px solid ${theme.surface3}`,
                  width: '100%',
                  maxWidth: '400px',
                  marginLeft: '20px',
                }}
              />
            </Row>
          </TokenHeader>

          {isSelected && !showBonded ? (
            <div
              onClick={() => {
                setIsSelected(false)
                setSelectedHeader('')
              }}
              style={{
                cursor: 'pointer',
                display: 'flex',
                color: `${theme.neutral2}`,
                alignItems: 'center',
                marginLeft: '60px',
              }}
            >
              <ArrowLeft color="grey" size={14} /> Back
            </div>
          ) : (
            <div style={{ height: '25px', width: '100%' }}></div>
          )}
        </ContentHeader>
        {/* {!isSelected && (
          <TokenGridWrapper>
            {Object.values(HeaderType).map((header) => (
              <TokenColumnHeader
                key={header}
                onClick={() => handleHeaderClick(header)}
                isSelectedHeader={selectedHeader === header}
                isSelected={isSelected}
              >
                {header}
              </TokenColumnHeader>
            ))}
          </TokenGridWrapper>
        )}*/}
        {isSelected && (
          <TokenGridWrapper>
            {Object.values(HeaderType).map((header) => (
              <TokenColumnHeader
                key={header}
                onClick={() => handleHeaderClick(header)}
                isSelectedHeader={selectedHeader === header}
                isSelected={isSelected}
              >
                {header}
              </TokenColumnHeader>
            ))}
          </TokenGridWrapper>
        )}
        <TokenGrid isSelected={isSelected} isSelectedHeader={false}>
          {!isSelected
            ? columns.map((column, columnIndex) => (
                <div key={columnIndex}>
                  <TokenGridWrapper>
                    <TokenColumnHeader
                      key={Object.values(HeaderType)[columnIndex]}
                      onClick={() => handleHeaderClick(Object.values(HeaderType)[columnIndex])}
                      isSelectedHeader={selectedHeader === Object.values(HeaderType)[columnIndex]}
                      isSelected={isSelected}
                    >
                      {Object.values(HeaderType)[columnIndex]}
                    </TokenColumnHeader>
                  </TokenGridWrapper>
                  {column.map((token, index) => (
                    <TokenCard
                      key={token.tokenCreated.id + '-' + index}
                      onClick={() => handleCardClick(token.tokenCreated.token)}
                      style={{ cursor: 'pointer' }}
                      className={newTokens.has(token.tokenCreated.token.toLowerCase()) ? 'new-token' : ''}
                    >
                      <img
                        src={'https://harlequin-tiny-dove-45.mypinata.cloud/ipfs/' + token.tokenCreated.imageCid}
                        height={64}
                        width={64}
                      />
                      {/* <RandomAvatar name={token.tokenCreated.name} square={true} size={80} /> */}
                      <TokenInfo>
                        <TokenName>
                          {token.tokenCreated.name} <div style={{ color: 'grey' }}>{token.tokenCreated.symbol}</div>
                        </TokenName>
                        <ProgressStack>
                          <div>
                            <ProgressLabel>
                              <span>Market Cap</span>
                              <span>{formatMarketCap(token.price, wnativePrice, showUSDPrice, nativeTokenName)}</span>
                            </ProgressLabel>
                            <ProgressBarOuter type="success">
                              <ProgressBarInner
                                progress={
                                  /*       100 * (mcap - startMcap) / deltaMcapRequired    */
                                  (100 * ((token.price * 1000000000) / 1e18 - (STARTING_MCAP_ETHER - 0.001))) /
                                  DELTA_MCAP_REQUIRED
                                }
                                type="success"
                              />
                            </ProgressBarOuter>
                          </div>
                        </ProgressStack>
                      </TokenInfo>
                    </TokenCard>
                  ))}
                  <ViewContainer style={{ marginLeft: '40px' }} onClick={() => handleViewMoreClick(columnIndex)}>
                    View More
                  </ViewContainer>
                </div>
              ))
            : currentTokens.map((token, index) => (
                <TokenCard
                  key={token.tokenCreated.id}
                  onClick={() => handleCardClick(token.tokenCreated.token)}
                  style={{ cursor: 'pointer' }}
                  className={newTokens.has(token.tokenCreated.token.toLowerCase()) ? 'new-token' : ''}
                >
                  <img
                    src={'https://harlequin-tiny-dove-45.mypinata.cloud/ipfs/' + token.tokenCreated.imageCid}
                    height={64}
                    width={64}
                  />
                  <TokenInfo>
                    <TokenName>
                      {token.tokenCreated.name} <div style={{ color: 'grey' }}>{token.tokenCreated.symbol}</div>
                    </TokenName>
                    <ProgressStack>
                      <div>
                        <ProgressLabel>
                          <span>Market Cap</span>
                          <span>${formatMarketCap(token.price, wnativePrice, showUSDPrice, nativeTokenName)}</span>
                        </ProgressLabel>
                        <ProgressBarOuter type="success">
                          <ProgressBarInner
                            progress={
                              (100 * ((token.price * 1000000000) / 1e18 - (STARTING_MCAP_ETHER - 0.001))) /
                              DELTA_MCAP_REQUIRED
                            }
                            type="success"
                          />
                        </ProgressBarOuter>
                      </div>
                    </ProgressStack>
                  </TokenInfo>
                </TokenCard>
              ))}
        </TokenGrid>
        <PaginationWrapper>
          {isSelected && (
            <>
              <NavCircle onClick={goToFirstPage} disabled={currentPage === 1}>
                {'<<'}
              </NavCircle>

              <NavCircle onClick={goToPreviousPage} disabled={currentPage === 1}>
                {'<'}
              </NavCircle>

              {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                <PageNumber key={page} isActive={currentPage === page} onClick={() => handlePageChange(page)}>
                  {page}
                </PageNumber>
              ))}

              <NavCircle onClick={goToNextPage} disabled={currentPage === totalPages}>
                {'>'}
              </NavCircle>

              <NavCircle onClick={goToLastPage} disabled={currentPage === totalPages}>
                {'>>'}
              </NavCircle>
            </>
          )}
        </PaginationWrapper>
      </TokenGridSection>
    </>
  )
}

export { ProgressBarInner, ProgressBarOuter, ProgressLabel, ProgressStack }
