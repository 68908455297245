import { BigNumber } from '@ethersproject/bignumber'
import { Trans } from '@lingui/macro'
import { ChainId, Currency, CurrencyAmount, NONFUNGIBLE_POSITION_MANAGER_ADDRESSES, Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { ButtonPrimary } from 'components/Button'
import { StyledNumericalInput } from 'components/CurrencyInputPanel'
import { Input } from 'components/NumericalInput'
import { ASSET_MANAGER_ADDRESS_MAP, MOCK_ORDER_FILLER } from 'constants/addresses'
import { nativeOnChain, WRAPPED_NATIVE_CURRENCY } from 'constants/tokens'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import {
  useAssetManager,
  useMembershipContract,
  useMockNFTOrderFillerContract,
  useMockOrderFillerContract,
  useNFTAccessPassContract,
  useV3NFTPositionManagerContract,
} from 'hooks/useContract'
import { useV2Pair } from 'hooks/useV2Pairs'
import { getAssetKey, getAssetKeyFromCurrency, useAccountIdentity } from 'hooks/v4/useAssetManagerOrderPermit'
import { useTokenBalance } from 'lib/hooks/useCurrencyBalance'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Text } from 'rebass'
import { useTransactionAdder } from 'state/transactions/hooks'
import styled from 'styled-components'
import { calculateGasMargin } from 'utils/calculateGasMargin'

// import { Input } from '@/components/ui/input'
import { LightCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import QuestionHelper from '../../components/QuestionHelper'
import { AutoRow } from '../../components/Row'
import { Dots } from '../../components/swap/styled'
import { BackArrowLink, StyledInternalLink, ThemedText } from '../../theme'
import { BodyWrapper } from '../AppBody'
import AssetBalances from './Components/assetBalances'
import NFTSelector from './Components/nftSelector'
import { getAssetManagerId } from './Utils/assetManagerUtils'

const StyledBodyWrapper = styled(BodyWrapper)`
  max-width: 600px;
  width: 100%;
`

const InputRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0.75rem 0.5rem 0.75rem 1rem;
`

const StyledInput = styled(Input)`
  width: 100%;
  background: none;
  border: none;
  outline: none;
  font-size: 18px;
`

export default function BuyMembership() {
  const membershipManager = useMembershipContract()
  const nftAccessPass = useNFTAccessPassContract()
  const mockOrderFiller = useMockOrderFillerContract()
  const mockNFTOrderFiller = useMockNFTOrderFillerContract()

  type NativeCurrencyAmountOrDefault = CurrencyAmount<Currency> | undefined

  const { account, chainId } = useWeb3React()
  const [entranceCost, setEntranceCost] = useState<NativeCurrencyAmountOrDefault>()
  const [membershipCost, setMembershipCost] = useState<NativeCurrencyAmountOrDefault>()
  const [refferFee, setRefferFee] = useState<NativeCurrencyAmountOrDefault>()
  const [treasuryFee, setTreasuryFee] = useState<NativeCurrencyAmountOrDefault>()
  const [userTreasuryFunds, setUserTreasuryFunds] = useState<NativeCurrencyAmountOrDefault>()
  const [refferId, setRefferId] = useState<BigNumber | null>()
  const [lpTokenId, setLPTokenId] = useState<BigNumber | null>()
  const [purchasedTokenId, setPurchasedTokenId] = useState<BigNumber | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const nftPosMan = useV3NFTPositionManagerContract(true)

  const [isNFTSelectorOpen, setNFTSelectorOpen] = useState(false)

  const openNFTSelector = () => setNFTSelectorOpen(true)
  const closeNFTSelector = () => setNFTSelectorOpen(false)

  const [relatedId, setRelatedId] = useState<BigNumber | undefined>(undefined)

  //TODO: Create A component To selet the organization
  const [organizationId, setOrganizationId] = useState<BigNumber | undefined>(BigNumber.from('2'))
  const handleNFTConfirm = (id: BigNumber) => {
    // Handle confirmed NFT selection here
    setRelatedId(id)
    setNFTSelectorOpen(false)
  }

  const accountHash = getAssetManagerId(organizationId, relatedId)
  const transactionAdder = useTransactionAdder()

  function formatAmount(amount: CurrencyAmount<Currency> | undefined): string {
    if (!amount) return ''
    return amount.toSignificant(6)
  }

  const nativeCurrency = useMemo(() => nativeOnChain(chainId ?? ChainId.BERA_TESTNET), [chainId])
  const toCurrencyAmount = useCallback(
    (amount: BigNumber) => CurrencyAmount.fromRawAmount(nativeCurrency, amount.toString()),
    [nativeCurrency]
  )

  //TODO: use Default Chain

  useEffect(() => {
    const fetchCosts = async () => {
      if (!membershipManager || !nftAccessPass) {
        return
      }
      setIsLoading(true)
      try {
        //TODO: this should use multicall instead of awaiting so many times
        const cost = await membershipManager.getEntranceCost()
        setEntranceCost(toCurrencyAmount(cost))

        const nftCost = await nftAccessPass.cost()
        setMembershipCost(toCurrencyAmount(nftCost))

        const remaining = cost.sub(nftCost)
        const refFee = await membershipManager.calculateFee(remaining, await membershipManager.refferFee())
        setRefferFee(toCurrencyAmount(refFee))

        const treasuryFee = await membershipManager.calculateFee(remaining, await membershipManager.treasuryFee())
        setTreasuryFee(toCurrencyAmount(treasuryFee))

        setUserTreasuryFunds(toCurrencyAmount(remaining.sub(refFee).sub(treasuryFee)))
      } catch (error) {
        console.error('Error fetching costs:', error)
      }
      setIsLoading(false)
    }

    fetchCosts()
  }, [membershipManager, nftAccessPass, toCurrencyAmount])

  const OFT_TOKEN = new Token(ChainId.BERA_TESTNET, '0x0A1855dD4201A05397CB895C375AFf630C85cAbe', 18, 'OFT')

  const oftTokenBalance = useTokenBalance(MOCK_ORDER_FILLER[ChainId.BERA_TESTNET], OFT_TOKEN)

  const accountIdentity = useAccountIdentity()
  const assetManager = useAssetManager()

  const wNative = WRAPPED_NATIVE_CURRENCY[ChainId.BERA_TESTNET]
  const [_, lpDepositPair] = useV2Pair(wNative, OFT_TOKEN)

  const lpDepositAmount = '10000'
  const [approvalLP, approveLPCallback] = useApproveCallback(
    lpDepositPair ? CurrencyAmount.fromRawAmount(lpDepositPair.liquidityToken, lpDepositAmount) : undefined,
    ASSET_MANAGER_ADDRESS_MAP[chainId ?? ChainId.BERA_TESTNET]
  )

  const mockFillOrder = async () => {
    if (!account || !mockOrderFiller) {
      return
    }

    if (!assetManager) {
      return
    }

    const orderNumber = 8
    const order = await assetManager.getOrder(orderNumber)
    console.log(order)
    console.log(oftTokenBalance)

    await mockOrderFiller
      .fillOrder(ASSET_MANAGER_ADDRESS_MAP[chainId ?? ChainId.BERA_TESTNET], orderNumber)
      .catch((err: any) => console.log(err))
  }

  const mockNFTFillOrder = async () => {
    if (!account || !mockNFTOrderFiller) {
      return
    }

    await mockNFTOrderFiller.fillOrder(ASSET_MANAGER_ADDRESS_MAP[chainId ?? ChainId.BERA_TESTNET], 4, 9)
  }

  const handleV3LiquidityDeposit = async () => {
    try {
      if (!account || !accountIdentity || !assetManager || !chainId || !nftPosMan || !lpTokenId) {
        return
      }

      const lpAssetKey = getAssetKey({
        address: NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId ?? ChainId.BERA_TESTNET],
        id: lpTokenId,
        isAssetUnknown: false,
      })

      if (!lpAssetKey) {
        return
      }

      //await nftPosMan.approve(ASSET_MANAGER_ADDRESS_MAP[chainId ?? ChainId.BERA_TESTNET], lpTokenId)
      const tx = await assetManager.estimateGas
        .deposit(accountIdentity, lpAssetKey, lpDepositAmount)
        .then((estimatedGasLimit) => {
          return assetManager
            .deposit(accountIdentity, lpAssetKey, lpDepositAmount, {
              gasLimit: calculateGasMargin(estimatedGasLimit),
            })
            .catch((error: any) => {
              // we only care if the error is something _other_ than the user rejected the tx
              if (error?.code !== 4001) {
                console.error(error)
              }
            })
        })
    } catch (error) {
      console.error('Error purchasing membership:', error)
    }
  }

  const handleLPDeposit = async () => {
    try {
      if (!account || !accountIdentity || !assetManager || !chainId || !lpDepositPair) {
        return
      }

      const lpAssetKey = getAssetKeyFromCurrency(lpDepositPair.liquidityToken)

      if (!lpAssetKey) {
        return
      }

      if (approvalLP == ApprovalState.NOT_APPROVED) {
        await approveLPCallback()
      }
      const tx = await assetManager.estimateGas
        .deposit(accountIdentity, lpAssetKey, lpDepositAmount)
        .then((estimatedGasLimit) => {
          return assetManager
            .deposit(accountIdentity, lpAssetKey, lpDepositAmount, {
              gasLimit: calculateGasMargin(estimatedGasLimit),
            })
            .catch((error: any) => {
              // we only care if the error is something _other_ than the user rejected the tx
              if (error?.code !== 4001) {
                console.error(error)
              }
            })
        })
    } catch (error) {
      console.error('Error purchasing membership:', error)
    }
  }

  const handleDeposit = async () => {
    try {
      if (!account || !accountIdentity || !assetManager || !chainId) {
        return
      }

      const native = nativeOnChain(chainId)
      const nativeAssetKey = getAssetKeyFromCurrency(native)

      if (!nativeAssetKey) {
        return
      }

      const amount = '1000000000000000000'

      const tx = await assetManager.estimateGas
        .deposit(accountIdentity, nativeAssetKey, amount, { value: amount })
        .then((estimatedGasLimit) => {
          return assetManager
            .deposit(accountIdentity, nativeAssetKey, amount, {
              value: amount,
              gasLimit: calculateGasMargin(estimatedGasLimit),
            })
            .catch((error: any) => {
              // we only care if the error is something _other_ than the user rejected the tx
              if (error?.code !== 4001) {
                console.error(error)
              }
            })
        })
    } catch (error) {
      console.error('Error purchasing membership:', error)
    }
  }

  const handlePurchase = async () => {
    try {
      if (!account || !membershipManager || !entranceCost) {
        return
      }
      const secondsInYearPlusLeap = 366 * 24 * 60 * 60
      const veReleaseTime = Math.floor(Date.now() / 1000) + secondsInYearPlusLeap
      const tx = await membershipManager.enter(account, 0, BigNumber.from(veReleaseTime), {
        value: entranceCost.numerator.toString(),
      })

      // TODO: figure out how to do this
      // const receipt = await tx.wait()

      // console.log(receipt)
      // const tokenId = BigNumber.from(receipt.logs[0].data)

      // setPurchasedTokenId(tokenId)
    } catch (error) {
      console.error('Error purchasing membership:', error)
    }
  }

  return (
    <div style={{ marginTop: '2em' }}>
      <StyledBodyWrapper>
        <AutoColumn gap="16px" style={{ padding: '24px' }}>
          <AutoRow style={{ alignItems: 'center', justifyContent: 'space-between' }} gap="8px">
            <BackArrowLink to="/pools" />
            <ThemedText.DeprecatedMediumHeader>
              <Trans>Buy Membership NFT</Trans>
            </ThemedText.DeprecatedMediumHeader>
            <div>
              <QuestionHelper
                text={<Trans>Purchase a membership NFT to join our DAO and access exclusive features.</Trans>}
              />
            </div>
          </AutoRow>

          <ThemedText.DeprecatedBody style={{ marginBottom: 8, fontWeight: 485 }}>
            <Trans>
              Purchasing a membership NFT grants you access to our DAO and its features. The cost includes fees for
              referrers and the treasury.
            </Trans>
          </ThemedText.DeprecatedBody>

          {isLoading ? (
            <LightCard padding="40px">
              <ThemedText.DeprecatedBody textAlign="center">
                <Dots>
                  <Trans>Loading</Trans>
                </Dots>
              </ThemedText.DeprecatedBody>
            </LightCard>
          ) : (
            <LightCard>
              <AutoColumn gap="16px">
                <InputRow>
                  <ThemedText.DeprecatedBody>Total Cost:</ThemedText.DeprecatedBody>
                  <ThemedText.DeprecatedBody marginLeft="auto">
                    {formatAmount(entranceCost)} ETH
                  </ThemedText.DeprecatedBody>
                </InputRow>
                <InputRow>
                  <ThemedText.DeprecatedBody>Membership NFT Cost:</ThemedText.DeprecatedBody>
                  <ThemedText.DeprecatedBody marginLeft="auto">
                    {formatAmount(membershipCost)} ETH
                  </ThemedText.DeprecatedBody>
                </InputRow>
                <InputRow>
                  <ThemedText.DeprecatedBody>Referrer Fee:</ThemedText.DeprecatedBody>
                  <ThemedText.DeprecatedBody marginLeft="auto">{formatAmount(refferFee)} ETH</ThemedText.DeprecatedBody>
                </InputRow>
                <InputRow>
                  <ThemedText.DeprecatedBody>Treasury Fee:</ThemedText.DeprecatedBody>
                  <ThemedText.DeprecatedBody marginLeft="auto">
                    {formatAmount(treasuryFee)} ETH
                  </ThemedText.DeprecatedBody>
                </InputRow>
                <InputRow>
                  <ThemedText.DeprecatedBody>Your Treasury Funds:</ThemedText.DeprecatedBody>
                  <ThemedText.DeprecatedBody marginLeft="auto">
                    {formatAmount(userTreasuryFunds)} ETH
                  </ThemedText.DeprecatedBody>
                </InputRow>
                <InputRow>
                  <StyledNumericalInput
                    type="number"
                    placeholder="Referrer ID (optional)"
                    value={refferId?.toNumber() ?? 0}
                    onUserInput={(e) => setRefferId(BigNumber.from(Number(e)))}
                    $loading={false}
                  />
                </InputRow>
                <ButtonPrimary onClick={handlePurchase} style={{ width: '100%' }}>
                  <Trans>Purchase Membership NFT</Trans>
                </ButtonPrimary>
                <ButtonPrimary onClick={handleV3LiquidityDeposit} style={{ width: '100%' }}>
                  <Trans>Deposit v3 LP token </Trans>
                </ButtonPrimary>
                <InputRow>
                  <StyledNumericalInput
                    type="Number"
                    placeholder="LP Token ID"
                    value={lpTokenId?.toNumber() ?? 0}
                    onUserInput={(e) => setLPTokenId(BigNumber.from(Number(e)))}
                    $loading={false}
                  />
                </InputRow>
                <ButtonPrimary onClick={handleDeposit} style={{ width: '100%' }}>
                  <Trans>Deposit Additional .1 Native </Trans>
                </ButtonPrimary>
                <ButtonPrimary onClick={handleLPDeposit} style={{ width: '100%' }}>
                  <Trans>Deposit WNative-USD LP </Trans>
                </ButtonPrimary>
                <ButtonPrimary onClick={openNFTSelector} style={{ width: '100%' }}>
                  <Trans>Choose an NFT Account</Trans>
                </ButtonPrimary>

                <NFTSelector isOpen={isNFTSelectorOpen} onConfirm={handleNFTConfirm} onDismiss={closeNFTSelector} />
                <ButtonPrimary onClick={mockFillOrder} style={{ width: '100%' }}>
                  <Trans>Fill Order</Trans>
                </ButtonPrimary>
                <ButtonPrimary onClick={mockNFTFillOrder} style={{ width: '100%' }}>
                  <Trans>Fill NFT Order</Trans>
                </ButtonPrimary>
              </AutoColumn>

              {accountHash && <AssetBalances accountHash={accountHash}></AssetBalances>}
            </LightCard>
          )}

          {purchasedTokenId && (
            <LightCard>
              <AutoColumn gap="16px">
                <ThemedText.DeprecatedLargeHeader>
                  <Trans>Congratulations!</Trans>
                </ThemedText.DeprecatedLargeHeader>
                <ThemedText.DeprecatedBody>
                  <Trans>Your Membership NFT ID: {purchasedTokenId.toString()}</Trans>
                </ThemedText.DeprecatedBody>
                {/* Here you would display the NFT artwork */}
              </AutoColumn>
            </LightCard>
          )}

          <AutoColumn justify="center" gap="md">
            <Text textAlign="center" fontSize={14} style={{ padding: '.5rem 0 .5rem 0' }}>
              <Trans>
                Need help? <StyledInternalLink to="/faq">Check our FAQ</StyledInternalLink>
              </Trans>
            </Text>
          </AutoColumn>
        </AutoColumn>
      </StyledBodyWrapper>
    </div>
  )
}
