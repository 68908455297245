import {
  Currency,
  CurrencyAmount,
  Fraction,
  Percent,
  Token,
  V2_FACTORY_ADDRESSES,
  V2_FACTORY_INIT_HASH,
} from '@uniswap/sdk-core'
import { computePairAddress, Pair } from '@uniswap/v2-sdk'
import { useWeb3React } from '@web3-react/core'
import { L2_CHAIN_IDS } from 'constants/chains'
import { SupportedLocale } from 'constants/locales'
import { L2_DEADLINE_FROM_NOW } from 'constants/misc'
import { useTotalSupply } from 'hooks/useTotalSupply'
import { PairState, useV2Pair } from 'hooks/useV2Pairs'
import JSBI from 'jsbi'
import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { RouterPreference } from 'state/routing/types'
import { UserAddedToken } from 'types/tokens'

import { BASES_TO_TRACK_LIQUIDITY_FOR, PINNED_PAIRS } from '../../constants/routing'
import { useDefaultActiveTokens } from '../../hooks/Tokens'
import { AppState } from '../reducer'
import {
  addSerializedPair,
  addSerializedToken,
  removeSerializedToken,
  updateHideClosedPositions,
  updateUserDeadline,
  updateUserLocale,
  updateUserRouterPreference,
  updateUserSlippageTolerance,
} from './reducer'
import { SerializedPair, SerializedToken, SlippageTolerance } from './types'

export function serializeToken(token: Token): SerializedToken {
  return {
    chainId: token.chainId,
    address: token.address,
    decimals: token.decimals,
    symbol: token.symbol,
    name: token.name,
  }
}

export function deserializeToken(serializedToken: SerializedToken, Class: typeof Token = Token): Token {
  return new Class(
    serializedToken.chainId,
    serializedToken.address,
    serializedToken.decimals,
    serializedToken.symbol,
    serializedToken.name
  )
}

export function useUserLocale(): SupportedLocale | null {
  return useAppSelector((state) => state.user.userLocale)
}

export function useUserLocaleManager(): [SupportedLocale | null, (newLocale: SupportedLocale) => void] {
  const dispatch = useAppDispatch()
  const locale = useUserLocale()

  const setLocale = useCallback(
    (newLocale: SupportedLocale) => {
      dispatch(updateUserLocale({ userLocale: newLocale }))
    },
    [dispatch]
  )

  return [locale, setLocale]
}

export function useRouterPreference(): [RouterPreference, (routerPreference: RouterPreference) => void] {
  const dispatch = useAppDispatch()

  // const routerPreference = useAppSelector((state) => state.user.userRouterPreference)

  const setRouterPreference = useCallback(
    (newRouterPreference: RouterPreference) => {
      dispatch(updateUserRouterPreference({ userRouterPreference: newRouterPreference }))
    },
    [dispatch]
  )

  const clientSideRouter = RouterPreference.CLIENT

  return [clientSideRouter, setRouterPreference]
}

/**
 * Return the user's slippage tolerance, from the redux store, and a function to update the slippage tolerance
 */
export function useUserSlippageTolerance(): [
  Percent | SlippageTolerance.Auto,
  (slippageTolerance: Percent | SlippageTolerance.Auto) => void
] {
  const userSlippageToleranceRaw = useAppSelector((state) => {
    return state.user.userSlippageTolerance
  })

  // TODO(WEB-1985): Keep `userSlippageTolerance` as Percent in Redux store and remove this conversion
  const userSlippageTolerance = useMemo(
    () =>
      userSlippageToleranceRaw === SlippageTolerance.Auto
        ? SlippageTolerance.Auto
        : new Percent(userSlippageToleranceRaw, 10_000),
    [userSlippageToleranceRaw]
  )

  const dispatch = useAppDispatch()
  const setUserSlippageTolerance = useCallback(
    (userSlippageTolerance: Percent | SlippageTolerance.Auto) => {
      let value: SlippageTolerance.Auto | number
      try {
        value =
          userSlippageTolerance === SlippageTolerance.Auto
            ? SlippageTolerance.Auto
            : JSBI.toNumber(userSlippageTolerance.multiply(10_000).quotient)
      } catch (error) {
        value = SlippageTolerance.Auto
      }
      dispatch(
        updateUserSlippageTolerance({
          userSlippageTolerance: value,
        })
      )
    },
    [dispatch]
  )

  return [userSlippageTolerance, setUserSlippageTolerance]
}

/**
 *Returns user slippage tolerance, replacing the auto with a default value
 * @param defaultSlippageTolerance the value to replace auto with
 */
export function useUserSlippageToleranceWithDefault(defaultSlippageTolerance: Percent): Percent {
  const [allowedSlippage] = useUserSlippageTolerance()
  return allowedSlippage === SlippageTolerance.Auto ? defaultSlippageTolerance : allowedSlippage
}

export function useUserHideClosedPositions(): [boolean, (newHideClosedPositions: boolean) => void] {
  const dispatch = useAppDispatch()

  const hideClosedPositions = useAppSelector((state) => state.user.userHideClosedPositions)

  const setHideClosedPositions = useCallback(
    (newHideClosedPositions: boolean) => {
      dispatch(updateHideClosedPositions({ userHideClosedPositions: newHideClosedPositions }))
    },
    [dispatch]
  )

  return [hideClosedPositions, setHideClosedPositions]
}

export function useUserTransactionTTL(): [number, (slippage: number) => void] {
  const { chainId } = useWeb3React()
  const dispatch = useAppDispatch()
  const userDeadline = useAppSelector((state) => state.user.userDeadline)
  const onL2 = Boolean(chainId && L2_CHAIN_IDS.includes(chainId))
  const deadline = onL2 ? L2_DEADLINE_FROM_NOW : userDeadline

  const setUserDeadline = useCallback(
    (userDeadline: number) => {
      dispatch(updateUserDeadline({ userDeadline }))
    },
    [dispatch]
  )

  return [deadline, setUserDeadline]
}

export function useAddUserToken(): (token: Token) => void {
  const dispatch = useAppDispatch()
  return useCallback(
    (token: Token) => {
      dispatch(addSerializedToken({ serializedToken: serializeToken(token) }))
    },
    [dispatch]
  )
}

export function useRemoveUserAddedToken(): (chainId: number, address: string) => void {
  const dispatch = useAppDispatch()
  return useCallback(
    (chainId: number, address: string) => {
      dispatch(removeSerializedToken({ chainId, address }))
    },
    [dispatch]
  )
}

function useUserAddedTokensOnChain(chainId: number | undefined | null): Token[] {
  const serializedTokensMap = useAppSelector(({ user: { tokens } }) => tokens)

  return useMemo(() => {
    if (!chainId) return []
    const tokenMap: Token[] = serializedTokensMap?.[chainId]
      ? Object.values(serializedTokensMap[chainId]).map((value) => deserializeToken(value, UserAddedToken))
      : []
    return tokenMap
  }, [serializedTokensMap, chainId])
}

export function useUserAddedTokens(): Token[] {
  return useUserAddedTokensOnChain(useWeb3React().chainId)
}

function serializePair(pair: Pair): SerializedPair {
  return {
    token0: serializeToken(pair.token0),
    token1: serializeToken(pair.token1),
  }
}

export function usePairAdder(): (pair: Pair) => void {
  const dispatch = useAppDispatch()

  return useCallback(
    (pair: Pair) => {
      dispatch(addSerializedPair({ serializedPair: serializePair(pair) }))
    },
    [dispatch]
  )
}

export function useURLWarningVisible(): boolean {
  return useAppSelector((state: AppState) => state.user.URLWarningVisible)
}

export function useUserDisabledUniswapX(): boolean {
  return useAppSelector((state) => state.user.disabledUniswapX) ?? false
}

/**
 * Given two tokens return the liquidity token that represents its liquidity shares
 * @param tokenA one of the two tokens
 * @param tokenB the other token
 */
export function toV2LiquidityToken([tokenA, tokenB]: [Token, Token]): Token {
  if (tokenA.chainId !== tokenB.chainId) throw new Error('Not matching chain IDs')
  if (tokenA.equals(tokenB)) throw new Error('Tokens cannot be equal')
  if (!V2_FACTORY_ADDRESSES[tokenA.chainId]) throw new Error('No V2 factory address on this chain')

  return new Token(
    tokenA.chainId,
    computePairAddress({
      factoryAddress: V2_FACTORY_ADDRESSES[tokenA.chainId],
      tokenA,
      tokenB,
      initHashCode: V2_FACTORY_INIT_HASH[tokenA.chainId],
    }),
    18,
    'Spooky-V2',
    'Spookyswap V2'
  )
}

//TODO: fix this this needs to account for no liquidity
// eslint-disable-next-line import/no-unused-modules
export function useExpectedV2LPAmount(
  minAmountA: CurrencyAmount<Currency> | undefined,
  minAmountB: CurrencyAmount<Currency> | undefined,
  allowedSlippage: Percent
): CurrencyAmount<Token> | undefined {
  // Ensure both minAmounts are defined before proceeding
  const hasValidAmounts = minAmountA !== undefined && minAmountB !== undefined

  // Determine tokens to sort
  const tokenA = minAmountA?.currency.wrapped
  const tokenB = minAmountB?.currency.wrapped

  // Get the pair and its state using wrapped tokens
  const [pairState, pair] = useV2Pair(tokenA, tokenB)
  const totalSupply = useTotalSupply(pair?.liquidityToken)

  // Determine if the pair has no liquidity
  const noLiquidity: boolean =
    !hasValidAmounts ||
    pairState === PairState.NOT_EXISTS ||
    Boolean(totalSupply && JSBI.equal(totalSupply.quotient, JSBI.BigInt(0))) ||
    Boolean(
      pairState === PairState.EXISTS &&
        pair &&
        JSBI.equal(pair.reserve0.quotient, JSBI.BigInt(0)) &&
        JSBI.equal(pair.reserve1.quotient, JSBI.BigInt(0))
    )

  // Compute the expected LP token amount
  const slippageAdjustedLPAmount = useMemo(() => {
    // Return undefined if any required value is missing
    if (
      !hasValidAmounts ||
      !pair ||
      !totalSupply ||
      noLiquidity ||
      !tokenA ||
      !tokenB ||
      pairState == PairState.LOADING ||
      pairState == PairState.INVALID
    ) {
      return undefined
    }

    // Sort tokens
    const [sortedTokenA, sortedTokenB] = tokenA.sortsBefore(tokenB)
      ? [minAmountA, minAmountB]
      : [minAmountB, minAmountA]

    return computeLiquidityAmount(
      sortedTokenA as CurrencyAmount<Token>,
      sortedTokenB as CurrencyAmount<Token>,
      pair.reserve0 as CurrencyAmount<Token>,
      pair.reserve1 as CurrencyAmount<Token>,
      totalSupply as CurrencyAmount<Token>,
      allowedSlippage
    )
  }, [
    hasValidAmounts,
    pair,
    totalSupply,
    noLiquidity,
    tokenA,
    tokenB,
    pairState,
    minAmountA,
    minAmountB,
    allowedSlippage,
  ])

  return slippageAdjustedLPAmount
}

function computeLiquidityAmount(
  minAmount0: CurrencyAmount<Token>,
  minAmount1: CurrencyAmount<Token>,
  reserve0: CurrencyAmount<Token>,
  reserve1: CurrencyAmount<Token>,
  totalSupply: CurrencyAmount<Token>,
  allowedSlippage: Percent
): CurrencyAmount<Token> {
  // Convert to BigInt for safe calculations
  const amount0 = JSBI.BigInt(minAmount0.quotient.toString())
  const amount1 = JSBI.BigInt(minAmount1.quotient.toString())
  const reserve0Amount = JSBI.BigInt(reserve0.quotient.toString())
  const reserve1Amount = JSBI.BigInt(reserve1.quotient.toString())
  const totalSupplyAmount = JSBI.BigInt(totalSupply.quotient.toString())

  // Calculate liquidity based on the formula
  const liquidityAmount0 = JSBI.divide(JSBI.multiply(amount0, totalSupplyAmount), reserve0Amount)
  const liquidityAmount1 = JSBI.divide(JSBI.multiply(amount1, totalSupplyAmount), reserve1Amount)

  // Return the minimum of the two liquidity amounts
  const liquidity = JSBI.lessThanOrEqual(liquidityAmount0, liquidityAmount1) ? liquidityAmount0 : liquidityAmount1

  // Adjust for slippage
  const slippageAdjustedLiquidity = new Fraction(JSBI.BigInt(1))
    .add(allowedSlippage)
    .invert()
    .multiply(liquidity).quotient

  // Return the liquidity as a CurrencyAmount<Token>
  return CurrencyAmount.fromRawAmount(totalSupply.currency, slippageAdjustedLiquidity)
}

/**
 * Returns all the pairs of tokens that are tracked by the user for the current chain ID.
 */
export function useTrackedTokenPairs(): [Token, Token][] {
  const { chainId } = useWeb3React()
  const tokens = useDefaultActiveTokens(chainId)

  // pinned pairs
  const pinnedPairs = useMemo(() => (chainId ? PINNED_PAIRS[chainId] ?? [] : []), [chainId])

  // pairs for every token against every base
  const generatedPairs: [Token, Token][] = useMemo(
    () =>
      chainId
        ? Object.keys(tokens).flatMap((tokenAddress) => {
            const token = tokens[tokenAddress]
            // for each token on the current chain,
            return (
              // loop though all bases on the current chain
              (BASES_TO_TRACK_LIQUIDITY_FOR[chainId] ?? [])
                // to construct pairs of the given token with each base
                .map((base) => {
                  if (base.address === token.address) {
                    return null
                  } else {
                    return [base, token]
                  }
                })
                .filter((p): p is [Token, Token] => p !== null)
            )
          })
        : [],
    [tokens, chainId]
  )

  // pairs saved by users
  const savedSerializedPairs = useAppSelector(({ user: { pairs } }) => pairs)

  const userPairs: [Token, Token][] = useMemo(() => {
    if (!chainId || !savedSerializedPairs) return []
    const forChain = savedSerializedPairs[chainId]
    if (!forChain) return []

    return Object.keys(forChain).map((pairId) => {
      return [deserializeToken(forChain[pairId].token0), deserializeToken(forChain[pairId].token1)]
    })
  }, [savedSerializedPairs, chainId])

  const combinedList = useMemo(
    () => userPairs.concat(generatedPairs).concat(pinnedPairs),
    [generatedPairs, pinnedPairs, userPairs]
  )

  return useMemo(() => {
    // dedupes pairs of tokens in the combined list
    const keyed = combinedList.reduce<{ [key: string]: [Token, Token] }>((memo, [tokenA, tokenB]) => {
      const sorted = tokenA.sortsBefore(tokenB)
      const key = sorted ? `${tokenA.address}:${tokenB.address}` : `${tokenB.address}:${tokenA.address}`
      if (memo[key]) return memo
      memo[key] = sorted ? [tokenA, tokenB] : [tokenB, tokenA]
      return memo
    }, {})

    return Object.keys(keyed).map((key) => keyed[key])
  }, [combinedList])
}
