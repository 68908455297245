import { defaultAbiCoder } from '@ethersproject/abi'
import { BigNumber } from '@ethersproject/bignumber'
import { keccak256 } from '@ethersproject/keccak256'

// eslint-disable-next-line import/no-unused-modules
export function getAssetManagerId(organizationId?: BigNumber, relatedId?: BigNumber): string | undefined {
  if (!organizationId || !relatedId) return
  return keccak256(
    defaultAbiCoder.encode(
      ['uint256', 'uint256'], // Encoding the BigNumbers as uint256 values
      [organizationId, relatedId]
    )
  )
}
