import { Trans } from '@lingui/macro'
import { BrowserEvent, InterfaceElementName, InterfaceEventName, InterfacePageName } from '@uniswap/analytics-events'
import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { Trace, TraceEvent } from 'analytics'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import { ButtonGray, ButtonPrimary, ButtonText } from 'components/Button'
import { AutoColumn } from 'components/Column'
import Img from 'components/Img'
import { FlyoutAlignment, LiquidityMenu, Menu } from 'components/Menu'
import PositionList from 'components/PositionList'
import { RowBetween, RowFixed } from 'components/Row'
import { SwitchLocaleLink } from 'components/SwitchLocaleLink'
import { isSupportedChain } from 'constants/chains'
import { useFilterPossiblyMaliciousPositions } from 'hooks/useFilterPossiblyMaliciousPositions'
import { useNetworkSupportsV2 } from 'hooks/useNetworkSupportsV2'
import { useV3Positions } from 'hooks/useV3Positions'
import { useAccountIdentityWithHash } from 'hooks/v4/useAssetManagerOrderPermit'
import { useAccountBalanceMetadata } from 'pages/MintNFT/Hooks/useAccountBalanceMetadata'
import { useIsAssetManagerActive } from 'pages/MintNFT/Hooks/useAssetManager'
import { useMemo } from 'react'
import { AlertTriangle, BookOpen, ChevronDown, ChevronsRight, Inbox, Layers } from 'react-feather'
import { Link } from 'react-router-dom'
import { useUserHideClosedPositions } from 'state/user/hooks'
import styled, { css, useTheme } from 'styled-components'
import { HideSmall, ThemedText } from 'theme'
import { PositionDetails } from 'types/position'
import { isV3Disabled } from 'utils/env'
import { isMobile } from 'utils/userAgent'

import CTACards from './CTACards'
import { LoadingRows } from './styled'

const PageWrapper = styled(AutoColumn)`
  padding: 68px 0px 0px;
  max-width: 870px;
  width: 100%;

  @media (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    max-width: 800px;
    padding-top: 48px;
  }

  @media (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    max-width: 500px;
    padding-top: 20px;
  }
`
const TitleRow = styled(RowBetween)`
  color: ${({ theme }) => theme.neutral2};
  @media (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
  }
`
const ButtonRow = styled(RowFixed)`
  & > *:not(:last-child) {
    margin-left: 8px;
    @media (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
      margin-left: 0px;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
`
const PoolMenu = styled(Menu)`
  margin-left: 0;
  @media (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    flex: 1 1 auto;
    width: 50%;
  }

  a {
    width: 100%;
  }
`
const LiquidityOptionMenu = styled(LiquidityMenu)`
  margin-left: 10px;
  @media (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    flex: 1 1 auto;
    width: 50%;
  }

  a {
    width: 100%;
  }
`
export const PoolMenuItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 500;
`
export const MoreOptionsButton = styled(ButtonGray)`
  border-radius: 12px;
  flex: 1 1 auto;
  padding: 6px 8px;
  width: 100%;
  background-color: ${({ theme }) => theme.surface1};
  border: 1px solid ${({ theme }) => theme.surface3};
  margin-right: 8px;
  @media (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    margin-right: 0px;
  }
`

export const MoreOptionsText = styled(ThemedText.BodyPrimary)`
  align-items: center;
  display: flex;
`

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 300px;
  min-height: 25vh;
`

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    flex-direction: column;
    text-wrap: nowrap;
    width: 100%;
    align-items: center;
  }
`

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`

const NetworkIcon = styled(AlertTriangle)`
  ${IconStyle}
`

const InboxIcon = styled(Inbox)`
  ${IconStyle}
`

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  border-radius: 12px;
  font-size: 16px;
  padding: 6px 8px;
  width: fit-content;
  @media (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    flex: 1 1 auto;
    width: 50%;
  }
`

const MainContentWrapper = styled.main`
  background-color: ${({ theme }) => theme.surface1};
  border: 1px solid ${({ theme }) => theme.surface3};
  padding: 0;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  overflow: hidden;
`

function PositionsLoadingPlaceholder() {
  return (
    <LoadingRows>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </LoadingRows>
  )
}

const CatContainer = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: ${({ gap }) => gap ?? 0};

  ${({ theme }) => theme.breakpoint.md} {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
`

const ImageWrapper = styled.div`
  padding-top: 40px;
  @media only screen and (max-width: 900px) {
    display: none;
  }
`

function WrongNetworkCard() {
  const theme = useTheme()

  return (
    <>
      <CatContainer>
        <ImageWrapper>
          {!isMobile && <Img src="https://assets.spooky.fi/LiquidityCats_Left.png" width={227} />}
        </ImageWrapper>
        <PageWrapper>
          <AutoColumn gap="lg" justify="center">
            <AutoColumn gap="lg" style={{ width: '100%' }}>
              <TitleRow padding="0">
                <ThemedText.LargeHeader>
                  <Trans>Pools</Trans>
                </ThemedText.LargeHeader>
              </TitleRow>
              <MainContentWrapper>
                <ErrorContainer>
                  <ThemedText.BodyPrimary color={theme.neutral3} textAlign="center">
                    <NetworkIcon strokeWidth={1.2} />
                    <div data-testid="pools-unsupported-err">
                      <Trans>Your connected network is unsupported.</Trans>
                    </div>
                  </ThemedText.BodyPrimary>
                </ErrorContainer>
              </MainContentWrapper>
            </AutoColumn>
          </AutoColumn>
        </PageWrapper>
        <ImageWrapper>
          {!isMobile && <Img src="https://assets.spooky.fi/LiquidityCats_Right.png" width={227} />}
        </ImageWrapper>
      </CatContainer>
      <SwitchLocaleLink />
    </>
  )
}

export default function Pool() {
  const { account, chainId } = useWeb3React()
  const networkSupportsV2 = useNetworkSupportsV2()
  const toggleWalletDrawer = useToggleAccountDrawer()
  const isAssetManager = useIsAssetManagerActive()

  const theme = useTheme()
  const [userHideClosedPositions, setUserHideClosedPositions] = useUserHideClosedPositions()

  const { positions: legacyPositions, loading: legacyPositionsLoading } = useV3Positions(account)
  const { positions: oldPositions, loading: oldPositionsLoading } = useV3Positions(account, true)

  const accountInfo = useAccountIdentityWithHash()
  const { metadata, isLoading, isError } = useAccountBalanceMetadata(accountInfo?.accountHash)

  const { positions, positionsLoading } = useMemo(() => {
    if (isAssetManager) {
      return { positions: metadata?.V3LPTokenMetadata.map((x) => x.position) ?? [], positionsLoading: isLoading }
    }

    return { positions: legacyPositions, positionsLoading: legacyPositionsLoading }
  }, [isAssetManager, isLoading, legacyPositions, legacyPositionsLoading, metadata?.V3LPTokenMetadata])

  const [openPositions, closedPositions] = positions?.reduce<[PositionDetails[], PositionDetails[]]>(
    (acc, p) => {
      acc[p.liquidity?.isZero() ? 1 : 0].push(p)
      return acc
    },
    [[], []]
  ) ?? [[], []]

  const userSelectedPositionSet = useMemo(
    () => [...openPositions, ...(userHideClosedPositions ? [] : closedPositions)],
    [closedPositions, openPositions, userHideClosedPositions]
  )

  const filteredPositions = useFilterPossiblyMaliciousPositions(userSelectedPositionSet)

  if (!isSupportedChain(chainId)) {
    return <WrongNetworkCard />
  }

  const showConnectAWallet = Boolean(!account)

  const staticMenuitems = [
    {
      content: (
        <PoolMenuItem>
          <Trans>V2 liquidity</Trans>
          <Layers size={16} />
        </PoolMenuItem>
      ),
      link: '/pools/v2',
      external: false,
    },
    {
      content: (
        <PoolMenuItem>
          <Trans>Learn</Trans>
          <BookOpen size={16} />
        </PoolMenuItem>
      ),
      link: 'https://v3.docs.spooky.fi/',
      external: true,
    },
  ]

  const menuItems = (
    isV3Disabled()
      ? []
      : [
          {
            content: (
              <PoolMenuItem>
                <Trans>Migrate</Trans>
                <ChevronsRight size={16} />
              </PoolMenuItem>
            ),
            link: '/migrate/v2',
            external: false,
          },
        ]
  ).concat(staticMenuitems)

  const liquidityMenuToggle = [
    {
      content: (
        <PoolMenuItem>
          <Trans>V2 liquidity</Trans>
          <Layers size={16} />
        </PoolMenuItem>
      ),
      link: '/pools/v2',
      external: false,
    },
  ]

  const linkNewPosition =
    chainId === ChainId.FANTOM ? '/add/ETH/0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE/3000' : '/add/ETH'

  return (
    <Trace page={InterfacePageName.POOL_PAGE} shouldLogImpression>
      <CatContainer>
        <ImageWrapper>
          {!isMobile && <Img src="https://assets.spooky.fi/LiquidityCats_Left.png" width={227} />}
        </ImageWrapper>
        <PageWrapper>
          <AutoColumn gap="lg" justify="center">
            <AutoColumn gap="lg" style={{ width: '100%' }}>
              <TitleRow padding="0">
                <TitleWrapper>
                  <ThemedText.LargeHeader>
                    <Trans>Pools</Trans>
                  </ThemedText.LargeHeader>
                  <LiquidityOptionMenu
                    menuItems={liquidityMenuToggle}
                    flyoutAlignment={FlyoutAlignment.LEFT}
                    ToggleUI={(props: any) => (
                      <MoreOptionsButton {...props}>
                        <MoreOptionsText>
                          <Trans>V3</Trans>
                          <ChevronDown size={15} />
                        </MoreOptionsText>
                      </MoreOptionsButton>
                    )}
                  />
                </TitleWrapper>

                <ButtonRow>
                  {networkSupportsV2 && (
                    <PoolMenu
                      menuItems={menuItems}
                      flyoutAlignment={FlyoutAlignment.LEFT}
                      ToggleUI={(props: any) => (
                        <MoreOptionsButton {...props}>
                          <MoreOptionsText>
                            <Trans>More</Trans>
                            <ChevronDown size={15} />
                          </MoreOptionsText>
                        </MoreOptionsButton>
                      )}
                    />
                  )}
                  <ResponsiveButtonPrimary
                    data-cy="join-pool-button"
                    id="join-pool-button"
                    as={Link}
                    to={linkNewPosition}
                  >
                    + <Trans>Add V3 Liquidity</Trans>
                  </ResponsiveButtonPrimary>
                </ButtonRow>
              </TitleRow>
              {oldPositions && oldPositions?.length > 0 && (
                <ThemedText.BodyPrimary color="yellow" textAlign="center">
                  <div>
                    <Trans>See your older positions </Trans>{' '}
                    <a href="https://oldnft.spooky.fi/#/pools?chain=sonic">here</a>
                  </div>
                </ThemedText.BodyPrimary>
              )}

              <MainContentWrapper>
                {positionsLoading ? (
                  <PositionsLoadingPlaceholder />
                ) : filteredPositions && closedPositions && filteredPositions.length > 0 ? (
                  <PositionList
                    positions={filteredPositions}
                    setUserHideClosedPositions={setUserHideClosedPositions}
                    userHideClosedPositions={userHideClosedPositions}
                  />
                ) : (
                  <ErrorContainer>
                    {oldPositions && oldPositions?.length > 0 && (
                      <ThemedText.BodyPrimary color="yellow" textAlign="center" fontSize="46">
                        <div>
                          <Trans>See your older positions </Trans>{' '}
                          <a href="https://oldnft.spooky.fi/#/pools?chain=sonic">here</a>
                        </div>
                      </ThemedText.BodyPrimary>
                    )}
                    {oldPositions?.length == 0 && (
                      <ThemedText.BodyPrimary color={theme.neutral3} textAlign="center">
                        <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
                        <div>
                          <Trans>Your active V3 liquidity positions will appear here.</Trans>
                        </div>
                      </ThemedText.BodyPrimary>
                    )}
                    {!showConnectAWallet && closedPositions.length > 0 && (
                      <ButtonText
                        style={{ marginTop: '.5rem' }}
                        onClick={() => setUserHideClosedPositions(!userHideClosedPositions)}
                      >
                        <Trans>Show closed positions</Trans>
                      </ButtonText>
                    )}
                    {showConnectAWallet && (
                      <TraceEvent
                        events={[BrowserEvent.onClick]}
                        name={InterfaceEventName.CONNECT_WALLET_BUTTON_CLICKED}
                        properties={{ received_swap_quote: false }}
                        element={InterfaceElementName.CONNECT_WALLET_BUTTON}
                      >
                        <ButtonPrimary
                          style={{ marginTop: '2em', marginBottom: '2em', padding: '8px 16px' }}
                          onClick={toggleWalletDrawer}
                        >
                          <Trans>Connect a wallet</Trans>
                        </ButtonPrimary>
                      </TraceEvent>
                    )}
                  </ErrorContainer>
                )}
              </MainContentWrapper>
              <HideSmall>
                <CTACards />
              </HideSmall>
            </AutoColumn>
          </AutoColumn>
        </PageWrapper>
        <ImageWrapper>
          {!isMobile && <Img src="https://assets.spooky.fi/LiquidityCats_Right.png" width={227} />}
        </ImageWrapper>
      </CatContainer>
      <SwitchLocaleLink />
    </Trace>
  )
}
