import { useWeb3React } from '@web3-react/core'
import axios from 'axios'
import { ButtonPrimary } from 'components/Button'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { useCurrency } from 'hooks/Tokens'
import React, { useEffect, useRef, useState } from 'react'
import { ArrowRight, Check, Maximize2, MessageCircle, Minimize2, Star, X } from 'react-feather'
import io from 'socket.io-client'
import styled, { useTheme } from 'styled-components'
import { Link } from 'react-router-dom'

const CHAT_API_URL = 'https://chatapi.spooky.fi:3001'

interface ChatboxProps {
  roomId?: string
  isExpandable?: boolean
  tokensFromPools: any
}

interface Message {
  sender: string
  text: string
  message: string
  timestamp: string
  roomId?: string
}

interface AuthResponse {
  success: boolean
}

const ChatWidgetWrapper = styled.div<{ isMinimized: boolean; isExpanded?: boolean; isExpandable?: boolean }>`
  background: ${({ theme }) => theme.surface2};
  border-radius: ${({ isExpanded }) => (isExpanded ? '0' : '8px')};
  border: 1px solid ${({ theme }) => theme.surface3};
  font-family: 'IBM Plex Mono', monospace;
  font-size: 13px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  width: ${({ isExpanded, isExpandable }) => {
    if (!isExpandable) return '100%'
    if (isExpanded) return '400px'
    return '320px'
  }};
  height: ${({ isMinimized, isExpanded }) => {
    if (isMinimized) return 'auto'
    if (isExpanded) return '100vh'
    return '480px'
  }};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: ${({ isExpandable }) => (isExpandable ? 'fixed' : 'relative')};
  right: ${({ isExpanded, isExpandable }) => {
    if (!isExpandable) return 'auto'
    return isExpanded ? '0' : '24px'
  }};
  bottom: ${({ isExpandable }) => (isExpandable ? '24px' : 'auto')};
  top: ${({ isExpanded, isExpandable }) => {
    if (!isExpandable) return 'auto'
    return isExpanded ? '0' : 'auto'
  }};
  transform: none;
  z-index: 1000;
  box-shadow: ${({ theme }) => `0 4px 12px ${theme.black}40`};

  @media (max-width: 768px) {
    display: none;
  }
`

const ChatHeader = styled.div`
  padding: 12px 16px;
  background: ${({ theme }) => theme.surface3};
  color: ${({ theme }) => theme.neutral1};
  font-weight: 500;
  text-align: left;
  border-bottom: 1px solid ${({ theme }) => theme.surface3};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const RoomBadge = styled.div<{ isGlobal?: boolean }>`
  background: ${({ theme, isGlobal }) => (isGlobal ? `${theme.accent1}33` : `${theme.success}33`)};
  color: ${({ theme, isGlobal }) => (isGlobal ? theme.accent1 : theme.success)};
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 10px;
  display: flex;
  align-items: center;
  gap: 4px;
`

const AddressBadge = styled.div<{ isUser?: boolean; isCreator?: boolean }>`
  background: ${({ theme, isUser, isCreator }) =>
    isCreator ? `${theme.accent1}33` : isUser ? `${theme.success}33` : `${theme.surface3}`};
  color: ${({ theme, isUser, isCreator }) => (isCreator ? theme.accent1 : isUser ? theme.success : theme.neutral2)};
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 11px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
`

const TokenInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 13px;
  background: ${({ theme }) => theme.surface3};
  padding: 4px 8px;
  border-radius: 12px;
  color: ${({ theme }) => theme.neutral1};
`

const TokenSymbol = styled.span`
  color: ${({ theme }) => theme.accent1};
  font-weight: 500;
`

const HeaderControls = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

const IconButton = styled.button`
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: ${({ theme }) => theme.neutral2};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s;

  &:hover {
    color: ${({ theme }) => theme.neutral1};
  }
`

const ChatBody = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  background: ${({ theme }) => theme.surface2};
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => `${theme.surface3} ${theme.surface2}`};
  min-height: 0;
  margin-bottom: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.surface2};
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.surface3};
    border-radius: 2px;
  }
`

const MessageRow = styled.div<{ isUser?: boolean }>`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  max-width: 85%;
  align-self: ${({ isUser }) => (isUser ? 'flex-end' : 'flex-start')};
  opacity: 0;
  animation: fadeIn 0.2s ease forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(5px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

const MessageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

const ChatText = styled.div<{ isUser?: boolean }>`
  font-size: 13px;
  color: ${({ theme, isUser }) => (isUser ? theme.accent1 : theme.neutral1)};
  background: ${({ theme, isUser }) => (isUser ? theme.surface3 : 'transparent')};
  padding: ${({ isUser }) => (isUser ? '6px 12px' : '0')};
  border-radius: ${({ isUser }) => (isUser ? '12px' : '0')};
`

const Timestamp = styled.span`
  font-size: 9px;
  color: ${({ theme }) => theme.neutral3};
  margin-top: 2px;
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background: ${({ theme }) => theme.surface3};
  border-top: 1px solid ${({ theme }) => theme.surface3};
  gap: 8px;
  width: 100%;
  position: sticky;
  bottom: 0;
  padding-bottom: max(16px, env(safe-area-inset-bottom, 16px));
`

const ChatInput = styled.input`
  padding: 8px 12px;
  border: 1px solid ${({ theme }) => theme.surface3};
  background: ${({ theme }) => theme.surface2};
  color: ${({ theme }) => theme.neutral1};
  border-radius: 6px;
  flex: 1;
  font-size: 13px;
  font-family: 'IBM Plex Mono', monospace;
  outline: none;
  transition: all 0.2s ease;

  &:focus {
    border-color: ${({ theme }) => theme.accent1};
  }

  &::placeholder {
    color: ${({ theme }) => theme.neutral3};
  }
`

const SendButton = styled(ButtonPrimary)`
  padding: 8px 16px;
  font-size: 13px;
  height: auto;
  min-height: unset;
`

const AuthPrompt = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 16px;
  background: ${({ theme }) => theme.surface3};
  border-top: 1px solid ${({ theme }) => theme.surface3};
  color: ${({ theme }) => theme.neutral2};
  font-size: 13px;
  width: 100%;
  position: sticky;
  bottom: 0;
  padding-bottom: max(16px, env(safe-area-inset-bottom, 16px));
`

const ConnectionStatus = styled.div<{ isConnected: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ theme, isConnected }) => (isConnected ? theme.success : theme.neutral3)};
  margin-left: 8px;
`

const ExpandButton = styled(IconButton)`
  position: absolute;
  right: 40px;
  top: 12px;
`

const FloatingButton = styled.button`
  display: none;
  position: fixed;
  right: 20px;
  bottom: 90px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: ${({ theme }) => theme.accent1};
  border: none;
  color: white;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    display: flex;
  }
`

const MobileChatOverlay = styled.div<{ isOpen: boolean }>`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  transition: opacity 0.3s ease;

  @media (max-width: 768px) {
    display: block;
  }
`

const MobileChatPanel = styled.div<{ isOpen: boolean }>`
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.surface2};
  border-radius: 20px 20px 0 0;
  z-index: 10000;
  transform: translateY(${({ isOpen }) => (isOpen ? '0' : '100%')});
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  height: 80vh;
  padding-bottom: env(safe-area-inset-bottom, 0px);
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    display: flex;
  }
`

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 1;
`

const MobileChatContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding-top: 60px;
`

// Add new styled component for scroll anchor
const ScrollAnchor = styled.div`
  height: 1px;
  width: 100%;
`

const Chatbox: React.FC<ChatboxProps> = ({ roomId = 'global', isExpandable = roomId === 'global', tokensFromPools }) => {
  const { account, provider } = useWeb3React()
  const theme = useTheme()
  const socketRef = useRef<any>(null)
  const [isMinimized, setIsMinimized] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const tokenCurrency = useCurrency(roomId !== 'global' ? roomId : undefined)

  const [messages, setMessages] = useState<Message[]>([])
  const [newMessage, setNewMessage] = useState('')
  const [authenticated, setAuthenticated] = useState(false)
  const [isConnected, setIsConnected] = useState(false)
  const chatBodyRef = useRef<HTMLDivElement>(null)
  const [creatorAddress, setCreatorAddress] = useState<string | null>(null)
  const [isMobileOpen, setIsMobileOpen] = useState(false)
  const messagesEndRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Initialize socket connection
    socketRef.current = io(CHAT_API_URL)
    const socket = socketRef.current

    socket.on('connect', () => setIsConnected(true))
    socket.on('disconnect', () => setIsConnected(false))

    socket.on('message', (msg: Message) => {
      if (msg.roomId === roomId) {
        setMessages((prev) => [
          ...prev,
          {
            sender: msg.sender,
            text: msg.message,
            message: msg.message,
            timestamp: msg.timestamp,
          },
        ])
      }
    })

    return () => {
      socket.off('connect')
      socket.off('disconnect')
      socket.off('message')
      socket.disconnect()
    }
  }, [roomId])

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView()
  }

  const fetchChatHistory = async () => {
    try {
      const response = await axios.get(`${CHAT_API_URL}/chat-history`, {
        params: {
          roomId,
          limit: 100,
        },
      })
      const history = response.data
      const formattedHistory = history.map((msg: any) => ({
        sender: msg.sender,
        text: msg.message,
        timestamp: msg.timestamp,
      }))
      setMessages(formattedHistory)
    } catch (error) {
      console.error('Failed to fetch chat history:', error)
    }
  }

  // Handle wallet changes
  useEffect(() => {
    if (authenticated && !account) {
      setAuthenticated(false)
      setMessages([])
    }
  }, [account])

  const authenticate = async () => {
    if (!provider || !account) {
      console.error('No provider or account available')
      return
    }

    try {
      const message = 'Sign this message to authenticate for the chat.'
      const signer = provider.getSigner()
      const signature = await signer.signMessage(message)

      socketRef.current.emit('authenticate', { address: account, signature, roomId })
      socketRef.current.on('authenticated', ({ success }: AuthResponse) => {
        setAuthenticated(success)
        if (success) {
          socketRef.current.emit('join-room', { roomId })
          fetchChatHistory()
        }
      })
    } catch (error) {
      console.error('Authentication failed:', error)
    }
  }

  const sendMessage = () => {
    if (!newMessage.trim() || !authenticated) return
    socketRef.current.emit('message', { message: newMessage, roomId })
    setNewMessage('')
  }

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      sendMessage()
    }
  }

  const addLinks = (msg: string) => {
    const split = msg.split(" ")
    return (<>
      {split.map((msg, idx) => {
        if(msg.startsWith("$")) {
          const filteredTokens = tokensFromPools
          .filter(
            (token) =>
              token.tokenCreated.symbol.toLowerCase() == msg.substring(1).toLowerCase()
          )
          // .filter(
          //   (token) => token.tokenCreated.tokenLauncheds.length == 0 //TODO Move this filter to graphql query
          // )
          
          return filteredTokens.length == 0 ? (msg + " ") : ( <Link to={filteredTokens[0].tokenCreated.id}>{msg + ' '}</Link>)
        }
          
        return (msg + " ")
      })
    }
      
    </>)
  }

  const formatTimestamp = (timestamp: string) => {
    const date = new Date(timestamp)
    const now = new Date()
    const isToday = date.toDateString() === now.toDateString()

    if (isToday) {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    }

    return date.toLocaleDateString([], {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
  }

  // Add this effect to fetch token creator address when in a token room
  useEffect(() => {
    const fetchCreator = async () => {
      if (roomId !== 'global') {
        try {
          // This is a placeholder - implement your actual creator fetch logic
          const response = await axios.get(`${CHAT_API_URL}/token-info/${roomId}`)
          setCreatorAddress(response.data.creator)
        } catch (error) {
          console.error('Failed to fetch token creator:', error)
        }
      }
    }
    fetchCreator()
  }, [roomId])

  useEffect(() => {
    // Load messages immediately without authentication
    fetchChatHistory()
  }, [roomId])

  // Add effect to scroll when messages change
  useEffect(() => {
    scrollToBottom()
  }, [messages])

  // Add effect to scroll when component mounts
  useEffect(() => {
    setTimeout(() => scrollToBottom(), 0)
  }, [])

  const toggleMobileChat = () => {
    setIsMobileOpen(!isMobileOpen)
    if (!isMobileOpen) {
      setTimeout(scrollToBottom, 300)
    }
  }

  return (
    <>
      <ChatWidgetWrapper isMinimized={isMinimized} isExpanded={isExpanded} isExpandable={isExpandable}>
        <ChatHeader>
          <HeaderTitle>
            {roomId === 'global' ? (
              <>
                <img src="https://assets.spooky.fi/spookyswap_logo.png" width={20} height={20} alt="Spooky" />
                <RoomBadge isGlobal>
                  <Star size={12} />
                  GLOBAL CHAT
                </RoomBadge>
              </>
            ) : (
              <TokenInfo>
                {tokenCurrency && <CurrencyLogo currency={tokenCurrency} size="20px" />}
                <TokenSymbol>{tokenCurrency?.symbol}</TokenSymbol>
                <RoomBadge>
                  <Check size={12} />
                  TOKEN ROOM
                </RoomBadge>
              </TokenInfo>
            )}
            <ConnectionStatus isConnected={isConnected} />
          </HeaderTitle>
          <HeaderControls>
            {authenticated && (
              <AddressBadge isUser>
                <Check size={12} />
                {account?.slice(0, 6)}...{account?.slice(-4)}
              </AddressBadge>
            )}
            {isExpandable && roomId === 'global' && (
              <ExpandButton onClick={() => setIsExpanded(!isExpanded)}>
                <ArrowRight size={16} style={{ transform: isExpanded ? 'rotate(180deg)' : 'none' }} />
              </ExpandButton>
            )}
            <IconButton onClick={() => setIsMinimized(!isMinimized)}>
              {isMinimized ? <Maximize2 size={16} /> : <Minimize2 size={16} />}
            </IconButton>
          </HeaderControls>
        </ChatHeader>
        {!isMinimized && (
          <>
            <ChatBody ref={chatBodyRef}>
              {messages.map((msg, idx) => {
                const isUser = msg.sender?.toLowerCase() === account?.toLowerCase()
                const isCreator = msg.sender?.toLowerCase() === creatorAddress?.toLowerCase()
                const senderAddrShort = `${msg.sender.slice(0, 6)}...${msg.sender.slice(-4)}`
                return (
                  <MessageRow key={idx} isUser={isUser}>
                    {!isUser && (
                      <AddressBadge isCreator={isCreator}>
                        {isCreator && <Star size={12} />}
                        {senderAddrShort}
                        {isCreator && ' • Creator'}
                      </AddressBadge>
                    )}
                    <MessageContent>
                      <ChatText isUser={isUser}>{addLinks(msg.text)}</ChatText>
                      <Timestamp>{formatTimestamp(msg.timestamp)}</Timestamp>
                    </MessageContent>
                  </MessageRow>
                )
              })}
              <div ref={messagesEndRef} />
            </ChatBody>
            {authenticated ? (
              <InputWrapper>
                <ChatInput
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyPress={handleKeyPress}
                  placeholder={
                    roomId === 'global'
                      ? 'Message in global chat...'
                      : `Message in ${tokenCurrency?.symbol || 'token'} room...`
                  }
                />
                <SendButton onClick={sendMessage} disabled={!newMessage.trim()}>
                  Send
                </SendButton>
              </InputWrapper>
            ) : (
              <AuthPrompt>
                {!account ? (
                  'Connect wallet to chat'
                ) : (
                  <>
                    <span>Sign in to start chatting</span>
                    <SendButton onClick={authenticate}>Sign In</SendButton>
                  </>
                )}
              </AuthPrompt>
            )}
          </>
        )}
      </ChatWidgetWrapper>

      <FloatingButton onClick={toggleMobileChat}>
        <MessageCircle size={24} />
      </FloatingButton>

      <MobileChatOverlay isOpen={isMobileOpen} onClick={toggleMobileChat} />

      <MobileChatPanel isOpen={isMobileOpen}>
        <CloseButton onClick={toggleMobileChat}>
          <X size={24} />
        </CloseButton>

        <ChatHeader>
          <HeaderTitle>
            {roomId === 'global' ? (
              <>
                <img src="https://assets.spooky.fi/spookyswap_logo.png" width={20} height={20} alt="Spooky" />
                <RoomBadge isGlobal>
                  <Star size={12} />
                  GLOBAL CHAT
                </RoomBadge>
              </>
            ) : (
              <TokenInfo>
                {tokenCurrency && <CurrencyLogo currency={tokenCurrency} size="20px" />}
                <TokenSymbol>{tokenCurrency?.symbol}</TokenSymbol>
                <RoomBadge>
                  <Check size={12} />
                  TOKEN ROOM
                </RoomBadge>
              </TokenInfo>
            )}
            <ConnectionStatus isConnected={isConnected} />
          </HeaderTitle>
          <HeaderControls>
            {authenticated && (
              <AddressBadge isUser>
                <Check size={12} />
                {account?.slice(0, 6)}...{account?.slice(-4)}
              </AddressBadge>
            )}
          </HeaderControls>
        </ChatHeader>

        <MobileChatContent>
          <ChatBody ref={chatBodyRef}>
            {messages.map((msg, idx) => {
              const isUser = msg.sender?.toLowerCase() === account?.toLowerCase()
              const isCreator = msg.sender?.toLowerCase() === creatorAddress?.toLowerCase()
              const senderAddrShort = `${msg.sender.slice(0, 6)}...${msg.sender.slice(-4)}`
              return (
                <MessageRow key={idx} isUser={isUser}>
                  {!isUser && (
                    <AddressBadge isCreator={isCreator}>
                      {isCreator && <Star size={12} />}
                      {senderAddrShort}
                      {isCreator && ' • Creator'}
                    </AddressBadge>
                  )}
                  <MessageContent>
                    <ChatText isUser={isUser}>{msg.text}</ChatText>
                    <Timestamp>{formatTimestamp(msg.timestamp)}</Timestamp>
                  </MessageContent>
                </MessageRow>
              )
            })}
          </ChatBody>

          {authenticated ? (
            <InputWrapper>
              <ChatInput
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder={
                  roomId === 'global'
                    ? 'Message in global chat...'
                    : `Message in ${tokenCurrency?.symbol || 'token'} room...`
                }
              />
              <SendButton onClick={sendMessage} disabled={!newMessage.trim()}>
                Send
              </SendButton>
            </InputWrapper>
          ) : (
            <AuthPrompt>
              {!account ? (
                'Connect wallet to chat'
              ) : (
                <>
                  <span>Sign in to start chatting</span>
                  <SendButton onClick={authenticate}>Sign In</SendButton>
                </>
              )}
            </AuthPrompt>
          )}
        </MobileChatContent>
      </MobileChatPanel>
    </>
  )
}

export default Chatbox
