import { BigNumber } from '@ethersproject/bignumber'
import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import Modal from 'components/Modal'
import { atom, useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { NFTImage } from 'pages/Pool/PositionPage'
import React, { useState } from 'react'
import styled from 'styled-components'
import { ThemedText } from 'theme'

import useOwnedNFTs from '../Hooks/useAccessPass'

// eslint-disable-next-line import/no-unused-modules
export const accountIdentityOrganizationIdAtom = atomWithStorage<BigNumber | null>(
  'accountOrganizationId',
  BigNumber.from(2)
)

// eslint-disable-next-line import/no-unused-modules
export const accountIdentityRelatedIdAtom = atomWithStorage<BigNumber | null>('accountRelatedId', null)

// Define the atom to store the account's selected NFTs
interface SelectedNFTMap {
  [accountAddress: string]: string // tokenId of selected NFT
}
const selectedNFTAtom = atom<SelectedNFTMap>({})

interface NFTSelectorProps {
  isOpen: boolean
  onConfirm: (id: BigNumber) => void
  onDismiss: () => void
}

const NFTSelector: React.FC<NFTSelectorProps> = ({ isOpen, onConfirm, onDismiss }) => {
  const { account } = useWeb3React()
  const ownedNFTs = useOwnedNFTs()
  const [selectedTokenId, setSelectedTokenId] = useState<string | null>(null)
  const [selectedNFTMap, setSelectedNFTMap] = useAtom(selectedNFTAtom)
  const [, setAccountIdentityRelatedId] = useAtom(accountIdentityRelatedIdAtom)

  const handleSelect = (tokenId: string) => {
    setSelectedTokenId(tokenId)
  }

  const handleConfirm = () => {
    if (!account || !selectedTokenId) {
      return
    }

    setSelectedNFTMap((prev) => ({
      ...prev,
      [account]: selectedTokenId,
    }))

    const tokenIdBN = BigNumber.from(selectedTokenId)
    setAccountIdentityRelatedId(tokenIdBN)
    onConfirm(tokenIdBN) // Trigger external confirmation action
  }

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={80} $scrollOverlay>
      <ModalContent>
        <Header>
          <ThemedText.SubHeaderSmall>
            <Trans>Select an NFT</Trans>
          </ThemedText.SubHeaderSmall>
          <DismissButton onClick={onDismiss}>✕</DismissButton>
        </Header>

        {!account ? (
          <Message>
            <Trans>Please connect your wallet to view your NFTs.</Trans>
          </Message>
        ) : (
          <Grid>
            {ownedNFTs.map((nft) => (
              <Token
                key={nft.tokenId}
                isSelected={nft.tokenId === selectedTokenId}
                onClick={() => handleSelect(nft.tokenId)}
              >
                <NFTImage alt={`NFT ${nft.tokenId}`} src={nft.imageUrl || '/nft/svgs/image-placeholder.svg'} />
                <span>{nft.tokenId}</span>
              </Token>
            ))}
          </Grid>
        )}

        <ConfirmButton onClick={handleConfirm}>
          <Trans>Confirm Selection</Trans>
        </ConfirmButton>
      </ModalContent>
    </Modal>
  )
}

export default NFTSelector

const ModalContent = styled.div`
  padding: 20px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #ccc;
`

const DismissButton = styled.button`
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`

const Message = styled.div`
  font-size: 16px;
  color: #333;
  text-align: center;
  padding: 20px 0;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin: 20px 0;
`

const Token = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  border: 2px solid ${({ isSelected }) => (isSelected ? '#4CAF50' : 'transparent')};
  transition: border-color 0.3s ease;

  &:hover {
    border-color: #4caf50;
  }
`

const ConfirmButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
`

const styles = {
  image: {
    width: '80px',
    height: '80px',
    borderRadius: '8px',
    marginBottom: '8px',
  },
}
