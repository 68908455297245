import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { assetManagerAtom } from 'components/AccountDrawer/AssetManagerToggle'
import { useAtomValue } from 'jotai/utils'

const V4_SUPPORTED_CHAINS = [ChainId.BERA_TESTNET]
export function useIsAssetManagerActive() {
  const { chainId } = useWeb3React()
  return useAtomValue(assetManagerAtom) && !!chainId && V4_SUPPORTED_CHAINS.includes(chainId)
}
